import { createIcons, icons } from "lucide";
import { createRef, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import '../../../assets/css/tabulator-edit.css';
import { useLoader } from "../../../base-components/Loader";
import Dialog from "../../../base-components/Headless/Dialog";
import Button from "../../../base-components/Button";
import Menu from "../../../base-components/Headless/Menu";
import Lucide from "../../../base-components/Lucide";
import * as xlsx from "xlsx";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/store";
import FormLabel from "../../../base-components/Form/FormLabel";
import FormSelect from "../../../base-components/Form/FormSelect";
import FormInput from "../../../base-components/Form/FormInput";

import Litepicker from "../../../base-components/Litepicker";
export default function Main() {


	const navigate = useNavigate()
	const { showLoader, hideLoader } = useLoader();
	const { groupid } = useParams();
	const tableRef = createRef<HTMLDivElement>();
	const tabulator = useRef<Tabulator>();
	const [from, setFrom] = useState('')
	const [to, setTo] = useState('')
	const [areaName, setAreaName] = useState<any>()
	const [agentNameS, setAgentNameS] = useState<any>()

	const [basicModalPreview, setBasicModalPreview] = useState(false)
	const [totPages, setTotPages] = useState(0)
	const [areaId, setAreaId] = useState('')
	const [agentIdS, setAgentIdS] = useState('')
	const [id, setId] = useState('');
	const [mode, setMode] = useState('0');
	const initClient = { chequeno: '', micRcode: '', transactionid: '', chequeDate: '' }
	const [agent, setAgent] = useState(initClient)
	const redrow = () => {
		if (tabulator.current) {
			tabulator.current.setData('/api/agent-track', { from: from, to: to, agentId: agentIdS, areaId: areaId })
			//console.log('redraw')
		}
	}
	const initTabulator = () => {
		if (tableRef.current) {
			// console.log(groupid);
			tabulator.current = new Tabulator(tableRef.current, {
				ajaxFiltering: true,

				ajaxURL: `/api/agent-track-enquery`, paginationMode: "remote", filterMode: "remote", sortMode: "remote",
				//ajaxParams: { from: from, to: to },
				movableColumns: true, ajaxResponse: (_url, p, res) => {
					//console.log('hello');
					setTotPages(res.last_page)
					const page = p.page, size = p.size
					const processedData = res.data.map((row, index) => ({ ...row, srno: ((page - 1) * size) + index + 1, }));

					res.data = processedData
					return res
				}, //placeholderHeaderFilter:"ijn",

				pagination: true, paginationSize: 10, paginationSizeSelector: [10, 20, 30, 40], paginationButtonCount: 10,
				reactiveData: true, height: 'auto', layout: "fitDataStretch", placeholder: "No matching records found",
				columns: [
					{ title: "Sr.", field: "srno", print: true, hozAlign: "center", headerHozAlign: 'center', headerSort: false },
					{ title: "Agent Name", field: "agent", minWidth: 200, print: true, hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
					{ title: "Date", field: "date", minWidth: 200, print: true, hozAlign: "center", headerHozAlign: 'center', validator: "required", headerSort: false },
					{ title: "Area", field: "area", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
					{ title: "Group", field: "groupName", print: true, minWidth: 100, hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
					{ title: "Member Name", field: "member", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
					
					{
						title: 'Map', minWidth: 100, headerSort: false,
						formatter: () => '<button class="bg-blue-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full">View Map</button>',
						cellClick: (e, cell) => {
							const planData = cell.getRow().getData();
							console.log(planData);
							window.open(`https://maps.google.com/?q=${planData.lat},${planData.lng}`, '_blank');
						}
					},
				],
			});
		}

		tabulator.current?.on("renderComplete", () => { createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", }); });
	};
	// Redraw table onresize
	const reInitOnResizeWindow = () => {
		window.addEventListener("resize", () => {
			if (tabulator.current) {
				tabulator.current.redraw();
				createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", });
			}
		});
	};
	function getFormValuesAsJson(form: HTMLFormElement): any {
		const formData = new FormData(form);
		const values: any = {};
		for (const entry of formData.entries()) {
			const [name, value] = entry;
			if (name === '') continue;
			if (Object.prototype.hasOwnProperty.call(values, name)) {
				if (typeof values[name] === 'string') values[name] = [values[name], value];
				else values[name].push(value);
			} else values[name] = value;
		}
		return values;
	}
	const onSubmit2 = async (event: React.ChangeEvent<HTMLFormElement>) => {
		event.preventDefault()
		try {

			showLoader()
			setAgent(getFormValuesAsJson(event.target))

			const cl = { micRcode: agent.micRcode, chequeno: agent.chequeno, chequeDate: agent.chequeDate, transactionid: agent.transactionid, mode: mode, id: Number(id) }
			//const ts = { id: Number(eventId), eventName: agent.eventName, eventAddress: agent.eventAddress, }

			const d = JSON.stringify(cl)
			//console.log(d)
			const f = await fetch('/api/loan-installment', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: d, })
			if (f.ok) {
				const data = await f.json();
				//console.log("success", data);
				setBasicModalPreview(false);
				navigate("/finance/loan-disbursement-list")
			} else console.error(f.status, f.statusText)

		} catch (error) { console.error(error) } finally { hideLoader() }
	}


	useEffect(() => {
		initTabulator()
		reInitOnResizeWindow()
		getAgentName()
		getAreaName()
		setTo('')
		setFrom('')
	}, [])
	useEffect(() => {
		console.log(mode);
	}, [mode])
	const onPrint = () => { if (tabulator.current) tabulator.current.print(); };
	//const onExportPdf = () => {
	//	if (tabulator.current) {
	//		const doc = new jsPDF({ orientation: 'portrait', unit: 'px', format: 'a4' })
	//		doc.autoTable({
	//			head: [['Sr.', 'Name', 'Group Name', 'Date', 'Disburment Amount', 'Installment Amount','Duration','Frequency','Processing Fee','Agent','Area']],
	//			body: tabulator.current.getData().map(row => [row.srno, row.name, row.groupName, row.date, row.disburseAmount,row.installmentAmount,row.duration,row.frequency,row.processingFee,row.agentName,row.area]),
	//		});

	//		doc.save('LoanApprovedList.pdf')
	//	}
	//	// tabulator.current.download("pdf", "data.pdf",{ jsPDF,orientation: 'portrait', title: '', autoTable: { styles: { fillColor: [255, 0, 0] } } });
	//};
	const onExportPdf = () => {
		if (tabulator.current) {
			const doc = new jsPDF({ orientation: 'landscape', unit: 'px', format: 'a4' });

			doc.autoTable({
				head: [['Sr.', 'Name', 'Group Name', 'Date', 'Disbursement Amount', 'Installment Amount', 'Duration', 'Frequency', 'Processing Fee', 'Agent', 'Area']],
				body: tabulator.current.getData().map(row => [
					row.srno,
					row.name,
					row.groupName,
					row.date,
					row.disburseAmount,
					row.installmentAmount,
					row.duration,
					row.frequency,
					row.processingFee,
					row.agentName,
					row.area
				]),
				margin: { top: 20, left: 20, right: 20, bottom: 20 }, // Adjust margins
				styles: {
					fontSize: 10, // Decrease font size to fit more data
					overflow: 'linebreak', // Handle long text overflow
				},
				columnStyles: {
					// Adjust specific column widths if needed
					4: { cellWidth: 60 },
					5: { cellWidth: 60 },
					8: { cellWidth: 60 },
					// Add more column styles if needed
				},
				tableWidth: 'wrap', // Fit the table to the page
			});

			doc.save('LoanApprovedList.pdf');
		}
	};

	const onExportCsv = () => { if (tabulator.current) tabulator.current.download("csv", "LoanApprovedList.csv"); };
	const onExportJson = () => { if (tabulator.current) tabulator.current.download("json", "LoanApprovedList.json"); };
	const onExportHtml = () => { if (tabulator.current) tabulator.current.download("html", "LoanApprovedList.html", { style: true, }); };

	const onExportXlsx = () => {
		if (tabulator.current) {
			(window as any).XLSX = xlsx;
			tabulator.current.download("xlsx", "LoanApprovedList.xlsx", { sheetName: "Products", });
		}
	};
	async function getAgentName() {
		try {

			showLoader()
			const f = await fetch('/api/get-agent-name')
			if (f.ok) {
				const r = await f.json()
				if (r.e) alert(r.d)
				else {
					setAgentNameS(r.d)
					//console.log(agentName);
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
	async function getAreaName() {
		try {

			showLoader()
			const f = await fetch('/api/get-area-name')
			if (f.ok) {
				const r = await f.json()
				if (r.e) alert(r.d)
				else {
					setAreaName(r.d)
					//console.log(agentName);
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
	return <>
		<div className="flex flex-col items-center mt-8 intro-y sm:flex-row"><h2 className="mr-auto text-lg font-medium">Loan Approval List </h2>

		</div>
		<div className="grid grid-cols-12 gap-4 gap-y-3">
			<div className="col-span-12 lg:col-span-2">
				<FormLabel htmlFor="modal-datepicker-1">From</FormLabel>
				<div className="relative w-56 mx-auto">
					<div className="absolute flex items-center justify-center w-10 h-full border rounded-l bg-slate-100 text-slate-500 dark:bg-darkmode-700 dark:border-darkmode-800 dark:text-slate-400">
						<Lucide icon="Calendar" className="w-4 h-4" />
					</div>
					<Litepicker
						id="modal-datepicker-1"
						value={from}
						onChange={setFrom}
						options={{
							autoApply: false, format: 'DD/MM/YYYY',
							showWeekNumbers: true,
							dropdowns: {
								minYear: 1990,
								maxYear: null,
								months: true,
								years: true,
							},
						}}
						className="pl-12" />
				</div>

			</div>
			<div className="col-span-12 lg:col-span-2">
				<FormLabel htmlFor="modal-datepicker-2">To</FormLabel>
				<div className="relative w-56 mx-auto">
					<div className="absolute flex items-center justify-center w-10 h-full border rounded-l bg-slate-100 text-slate-500 dark:bg-darkmode-700 dark:border-darkmode-800 dark:text-slate-400">
						<Lucide icon="Calendar" className="w-4 h-4" />
					</div>
					<Litepicker
						id="modal-datepicker-2"
						value={to}
						onChange={setTo}
						options={{
							autoApply: false, format: 'DD/MM/YYYY',
							showWeekNumbers: true,
							dropdowns: {
								minYear: 1990,
								maxYear: null,
								months: true,
								years: true,
							},
						}}
						className="pl-12" />
				</div>

			</div>

			<div className="col-span-12 lg:col-span-3 lg:ml-9 lg:mt-7">
				<div className="input-form mb-5">
					{/*	<FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row"> Agent Name</FormLabel>*/}

					<FormSelect id='sCat' onChange={(e) => setAgentIdS(e.currentTarget.value)} name='categoryId' value={agentIdS} aria-label="Default select example">
						<option value='0'>Select Agent</option>
						{(agentNameS != undefined && agentNameS && agentNameS.length > 0) ? agentNameS.map((cat: any) => (
							<option value={cat.id} key={cat.id}>{cat.name}</option>
						)) : <option>Nothing to show</option>}
					</FormSelect>
				</div>
			</div>
			{/*<div className="col-span-12 lg:col-span-2 flex items-end mb-5">*/}
			{/*	<Button className="mr-2 w-full" variant="primary" onClick={() => redrow()}>Search</Button>*/}
			{/*</div>*/}
			{/*<div className="col-span-12 lg:col-span-3  lg:mt-7">*/}
			{/*	<div className="input-form mb-5">*/}
			{/*		*/}{/*<FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row"> Area Name</FormLabel>*/}

			{/*		<FormSelect id='sCat' onChange={(e) => setAreaId(e.currentTarget.value)} name='categoryId' value={areaId} aria-label="Default select example">*/}
			{/*			<option value='0'>Select Area</option>*/}
			{/*			{(areaName != undefined && areaName && areaName.length > 0) ? areaName.map((cat: any) => (*/}
			{/*				<option value={cat.id} key={cat.id}>{cat.name}</option>*/}
			{/*			)) : <option>Nothing to show</option>}*/}
			{/*		</FormSelect>*/}
			{/*	</div>*/}
			{/*</div>*/}
			<div className="col-span-12 lg:col-span-2 flex items-end mb-5">
				<Button className="mr-2 w-full" variant="primary" onClick={() => redrow()}>Search</Button>

			</div>
		</div>
		<div className="p-5 mt-5 intro-y box">
			<div className="flex flex-col sm:flex-row sm:items-end xl:items-start">
				<div className="xl:flex sm:mr-auto">

					<Button id="tabulator-print" className="w-1/2 mr-2 sm:w-auto" variant="outline-secondary" onClick={onPrint}><Lucide icon="Printer" className="w-4 h-4 mr-2" />Print</Button>
					<Menu className="w-1/2 sm:w-auto">
						<Menu.Button as={Button} variant="outline-secondary" className="w-full sm:w-auto">
							<Lucide icon="FileText" className="w-4 h-4 mr-2" /> Export
							<Lucide icon="ChevronDown" className="w-4 h-4 ml-auto sm:ml-2" />
						</Menu.Button>
						<Menu.Items className="w-40">
							<Menu.Item onClick={onExportPdf}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export PDF</Menu.Item>
							<Menu.Item onClick={onExportCsv}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export CSV</Menu.Item>
							<Menu.Item onClick={onExportJson}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export JSON</Menu.Item>
							<Menu.Item onClick={onExportXlsx}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export XLSX</Menu.Item>
							<Menu.Item onClick={onExportHtml}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export HTML</Menu.Item>
						</Menu.Items>
					</Menu>
				</div>
				<Button onClick={() => {

					if (tabulator.current) {
						tabulator.current.setData()
						setTo('')
						setFrom('')
						setAgentIdS('')
						setAreaId('')
					}

				}}><Lucide icon="RefreshCcw" className="w-4 h-4 mr-3" /></Button>
			</div>
			<div className="overflow-x-auto scrollbar-hidden">
				<div id="tabulator" ref={tableRef} className="mt-5"></div>
			</div>
			<div className='flex w-full'>
				<span className='ml-auto mr-10'><b>Total Pages: </b>{totPages}</span>
			</div>
		</div>
		

	</>
}