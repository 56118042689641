import { createIcons, icons } from "lucide";
import { createRef, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GroupComponent, TabulatorFull as Tabulator } from "tabulator-tables";
import '../../../assets/css/tabulator-edit.css';
import { useLoader } from "../../../base-components/Loader";
import Button from "../../../base-components/Button";
import Menu from "../../../base-components/Headless/Menu";
import Lucide from "../../../base-components/Lucide";
import Dialog from "../../../base-components/Headless/Dialog";
import jsPDF from "jspdf";
import * as xlsx from "xlsx";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/store";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import FormLabel from "../../../base-components/Form/FormLabel";
import FormInput from "../../../base-components/Form/FormInput";
import clsx from "clsx";
import FormSelect from "../../../base-components/Form/FormSelect";
import Litepicker from "../../../base-components/Litepicker";
import DocumentView from "../sharedComponent/DocumentView";
import GuarantorView from "../sharedComponent/GuarantorView";
import FullDetailsComponent from "../sharedComponent/FullDetailsView";

export default function Main() {
    const navigate = useNavigate()
    const { showLoader, hideLoader } = useLoader();
    const { groupid } = useParams();
    const tableRef = createRef<HTMLDivElement>();
    const tabulator = useRef<Tabulator>();

    const [modal, setModal] = useState({ msg: '', title: '', type: '' });
    const [deleteModalPreview, setDeleteModalPreview] = useState(false)
    const [detailsShow, setDetailsShow] = useState(false)
    const [totPages, setTotPages] = useState(0)
    const [planType, setPlanType] = useState<any>()
    const [planName, setPlanName] = useState<any>()
    const [planDetails, setPlanDetails] = useState<any>()
    const dateRange1 = useSelector((state: RootState) => state.dateRange.dateRange ?? "");

    const [dateRange, setDateRange] = useState(dateRange1);
    const [isUpdate, setIsUpdate] = useState(false);
    const [invalidAlert, setInvalidAlert] = useState(false);
    const [castes, setCastes] = useState([]);
    //const initClient = { name: '', mobile: '', address: '' }
    //const [agent, setAgent] = useState(initClient)
    const [memberId, setMemberId] = useState('');
    //const schema = yup.object().shape({
    //    //id: yup.number(),
    //    name: yup.string().trim().required().min(2),
    //    mobile: yup.string().required('Caste is required').matches(/\d{10}/, { excludeEmptyString: true, message: 'Invalid phone number format', }),
    //    //.matches(/^[1-9]\d*$/, { excludeEmptyString: true, message: 'Caste is required', }),
    //    address: yup.string().required().min(4),
    //    //remobile: yup.string().required().min(2),
    //    //email: yup.string().email(),
    //    //mobile1: yup.string().required().min(10).max(10)
    //    //	.matches(/\d{10}/, { excludeEmptyString: true, message: 'Invalid phone number format', }),
    //    //mobile2: yup.string().min(10).max(10)//.required()
    //    //	.matches(/\d{10}/, { excludeEmptyString: true, message: 'Invalid phone number format', }),
    //}).required();

    //const { register, trigger, formState: { errors }, } = useForm({//defaultValues: client,
    //    criteriaMode: 'all', mode: "onSubmit", shouldFocusError: true,
    //    values: agent, reValidateMode: 'onChange',
    //    resolver: yupResolver(schema),
    //})
    var copyIcon = function () { return '<i data-lucide="bell-plus" className="w-8 h-8 mr-1 text-success"></i> Remind'; };

    const initTabulator = () => {
        if (tableRef.current) {
            // console.log(groupid);
            tabulator.current = new Tabulator(tableRef.current, {
                ajaxFiltering: true,
                //rowHeader: {
                //    headerSort: false, resizable: false, frozen: true, headerHozAlign: "center", hozAlign: "center", formatter: "rowSelection", titleFormatter: "rowSelection", cellClick: function (e, cell) {
                //        cell.getRow().toggleSelect();
                //    }
                //},
                ajaxURL: `/api/member-list-reloan/${groupid}`, paginationMode: "remote", filterMode: "remote", sortMode: "remote",
                movableColumns: true, ajaxResponse: (_url, p, res) => {
                    //console.log('hello');
                    setTotPages(res.last_page)
                    const page = p.page, size = p.size
                    const processedData = res.data.map((row, index) => ({ ...row, srno: ((page - 1) * size) + index + 1, }));

                    res.data = processedData
                    return res
                }, //placeholderHeaderFilter:"ijn",

                pagination: true, paginationSize: 10, paginationSizeSelector: [10, 20, 30, 40], paginationButtonCount: 10,
                reactiveData: true, height: 'auto', layout: "fitDataStretch", placeholder: "No matching records found",
                columns: [
                    { title: "Sr.", field: "srno", print: true, hozAlign: "center", headerHozAlign: 'center', headerSort: false },
                    { title: "Name", field: "name", minWidth: 200, print: true, hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
                    { title: "GroupName", field: "groupName", print: true, minWidth: 150, hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
                    { title: "Date", field: "date", minWidth: 200, print: true, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "input", validator: "required", headerSort: false },
                    { title: "Address", field: "address", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
                    { title: "Leader", field: "isLeader", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
                    { title: "Agent", field: "agentName", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
                    { title: "Area", field: "area", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
                    //{
                    //    title: 'delete', minWidth: 100,
                    //    formatter: () => '<i data-lucide="trash2" class="w-8 h-8 mr-1 text-danger"></i>',
                    //    cellClick: (e, cell) => {
                    //        const planData = cell.getRow().getData();
                    //        setMemberId(planData.id);
                    //        //setSuperlargeModalSizePreview1(true);
                    //        //navigate(`/finance/expenses/${planData.id}`);
                    //    },
                    //},

                ],
            });
        }

        tabulator.current?.on("renderComplete", () => { createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", }); });
    };
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
        window.addEventListener("resize", () => {
            if (tabulator.current) {
                tabulator.current.redraw();
                createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", });
            }
        });
    };


    const onSubmit2 = async () => {
        // event.preventDefault()
        try {
            
            showLoader()
            const cl = { groupId: groupid, planId: Number(planType), disbursementAmount: planDetails.amount, installmentAmount: planDetails.installment, duration: planDetails.duration, frequencyId: planDetails.frequency, processingFee: planDetails.processingFee, interest: planDetails.interest }

            const d = JSON.stringify(cl)
            console.log(d)
            const f = await fetch('/api/reloan-disburse', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: d, })
            if (f.ok) {
                const data = await f.json();
                console.log("success", data);
                setInvalidAlert(true);

            } else console.error(f.status, f.statusText)

        } catch (error) { console.error(error) } finally { hideLoader() }
    }


    async function getCategories() {
        try {


            const f = await fetch('/api/planloan-list')
            if (f.ok) {
                const r = await f.json()
                if (r.e) alert(r.d)
                else {
                    setPlanName(r.d)
                    // console.log(planName);
                }
            } else console.error(f.status, f.statusText)
        } catch (e) { console.error(e) } finally { hideLoader() }
    }
    async function getDetails(id: any) {
        try {
            console.log({ id });
            const f = await fetch(`/api/planloan-list/${id}`)
            if (f.ok) {
                const r = await f.json()
                if (r.e) alert(r.d)
                else {
                    setPlanDetails(r.d)
                }
            } else console.error(f.status, f.statusText)
        } catch (e) { console.error(e) } finally { hideLoader() }
    }
    useEffect(() => {
        if (planType != undefined && planType !== '0') {
            getDetails(planType);
            setDetailsShow(true);
        }
    }, [planType])
    //useEffect(() => {
    //                console.log(planDetails);
    //}, [planDetails])

    useEffect(() => {
        initTabulator()
        getCategories()
        reInitOnResizeWindow()

    }, [])
    const onPrint = () => { if (tabulator.current) tabulator.current.print(); };
    const onExportPdf = () => {
        if (tabulator.current) {
            const doc = new jsPDF({ orientation: 'portrait', unit: 'px', format: 'a4' })
            doc.autoTable({
                head: [['Sr.', 'Name', 'Group Name', 'Date', 'Address', 'isLeader', 'Agent']],
                body: tabulator.current.getData().map(row => [row.srno, row.name, row.groupName, row.date, row.address, row.isLeader, row.agentName]),
            });

            doc.save('Expenses.pdf')
        }
        // tabulator.current.download("pdf", "data.pdf",{ jsPDF,orientation: 'portrait', title: '', autoTable: { styles: { fillColor: [255, 0, 0] } } });
    };
    const onExportCsv = () => { if (tabulator.current) tabulator.current.download("csv", "data.csv"); };
    const onExportJson = () => { if (tabulator.current) tabulator.current.download("json", "data.json"); };
    const onExportHtml = () => { if (tabulator.current) tabulator.current.download("html", "data.html", { style: true, }); };

    const onExportXlsx = () => {
        if (tabulator.current) {
            (window as any).XLSX = xlsx;
            tabulator.current.download("xlsx", "data.xlsx", { sheetName: "Products", });
        }
    };
    return <>

        <div className="p-5 mt-5 intro-y box">
            {/*<div className="flex flex-col sm:flex-row sm:items-end xl:items-start">*/}
            {/*    <div className="xl:flex sm:mr-auto">*/}

            {/*        <Button id="tabulator-print" className="w-1/2 mr-2 sm:w-auto" variant="outline-secondary" onClick={onPrint}><Lucide icon="Printer" className="w-4 h-4 mr-2" />Print</Button>*/}
            {/*        <Menu className="w-1/2 sm:w-auto">*/}
            {/*            <Menu.Button as={Button} variant="outline-secondary" className="w-full sm:w-auto">*/}
            {/*                <Lucide icon="FileText" className="w-4 h-4 mr-2" /> Export*/}
            {/*                <Lucide icon="ChevronDown" className="w-4 h-4 ml-auto sm:ml-2" />*/}
            {/*            </Menu.Button>*/}
            {/*            <Menu.Items className="w-40">*/}
            {/*                <Menu.Item onClick={onExportPdf}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export PDF</Menu.Item>*/}
            {/*                <Menu.Item onClick={onExportCsv}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export CSV</Menu.Item>*/}
            {/*                <Menu.Item onClick={onExportJson}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export JSON</Menu.Item>*/}
            {/*                <Menu.Item onClick={onExportXlsx}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export XLSX</Menu.Item>*/}
            {/*                <Menu.Item onClick={onExportHtml}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export HTML</Menu.Item>*/}
            {/*            </Menu.Items>*/}
            {/*        </Menu>*/}
            {/*    </div>*/}

            {/*</div>*/}
            <div className="overflow-x-auto scrollbar-hidden">
                <div id="tabulator" ref={tableRef} className="mt-5"></div>
            </div>
            <div className='flex w-full'>
                <span className='ml-auto mr-10'><b>Total Pages: </b>{totPages}</span>
            </div>
        </div>
        <div className="col-span-12 intro-y lg:col-span-6 my-5 sm:ml-32">
            <div className=" grid sm:grid-cols-4 gap-4  ">
                <div className="flex sm:justify-end sm:pt-1 ">
                    <p className="sm:text-lg sm:font-semibold">Select Plan :</p>
                </div>
                <div className="">
                    <FormSelect onChange={(e) => {
                        //const selectedPlanType = e.currentTarget.value;
                        setPlanType(e.currentTarget.value);

                    }
                    } value={planType} aria-label="Default select example">
                        <option value='0'>Select Type</option>
                        {(planName != undefined && planName && planName.length > 0) ? planName.map((cat: any) => (
                            <option value={cat.id} key={cat.id}>{cat.name}</option>
                        )) : <option>Nothing to show</option>}
                    </FormSelect>
                </div>
                {/*<div className="flex flex-col w-full sm:flex-row">*/}
                {/*    <Button onClick={() => {*/}
                {/*        getDetails(planType); */}
                {/*        setDetailsShow(true);*/}
                {/*    }}> Check Plan Details </Button>*/}
                {/*</div>*/}
            </div>
        </div>
        {detailsShow && (<>
            <div className="p-5 mt-5 intro-y box">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="space-y-2">
                        <p className="font-semibold">Plan Amount :</p>
                        <p className="font-semibold">Installment :</p>
                        <p className="font-semibold">Duration :</p>
                        <p className="font-semibold">Frequency :</p>
                        <p className="font-semibold">Processing Fee :</p>
                        <p className="font-semibold">Interest :</p>
                    </div>
                    {planDetails && < div className="space-y-2">
                        <p>{planDetails.amount}</p>
                        <p>{planDetails.installment}</p>
                        <p>{planDetails.duration}</p>
                        <p>{planDetails.frequency}</p>
                        <p>{planDetails.processingFee}</p>
                        <p>{planDetails.interest}</p>
                    </div>}
                </div>
            </div>
            <div className="flex justify-center items-center w-full sm:w-auto  mt-5">
                <Button
                    className="w-full sm:w-auto sm:px-8 py-2 text-center bg-blue-600 text-white hover:bg-blue-700 rounded"
                    onClick={() => {
                        setDeleteModalPreview(true)

                    }}
                >
                    Approve Loan
                </Button>
            </div></>
        )}
        <Dialog open={deleteModalPreview} onClose={() => {
            setDeleteModalPreview(false);
        }}

        >
            <Dialog.Panel>
                <div className="p-5 text-center">
                    <Lucide icon="CheckIcon" className="w-16 h-16 mx-auto mt-3 text-success" />
                    <div className="mt-5 text-3xl">Are you sure?</div>
                    <div className="mt-2 text-slate-500">
                        Do you really want to Approve these records? <br />
                        This process cannot be undone.
                    </div>
                </div>
                <div className="px-5 pb-8 text-center">
                    <Button type="button" variant="outline-secondary" onClick={() => {
                        setDeleteModalPreview(false);
                    }}
                        className="w-24 mr-1"
                    >
                        Cancel
                    </Button>
                    <Button type="button" variant="success" className="w-24" onClick={() => {
                        if (planType) onSubmit2();
                        setDeleteModalPreview(false);
                    }} >
                        Approve
                    </Button>
                </div>
            </Dialog.Panel>
        </Dialog>

        <Dialog id='failed-notification-content' staticBackdrop open={invalidAlert} onClose={() => { setInvalidAlert(false); }} >
            <Dialog.Panel className="px-5 py-10">
                <div className="text-center">
                    <div className="mb-5">Loan Approved successfully..!</div>
                    <Button type="button" variant="primary" className="w-24 mr-2" onClick={() => {
                        navigate('/finance/loan-approval-list')
                        setInvalidAlert(false)
                    }}>Ok</Button>
                </div>
            </Dialog.Panel>
        </Dialog>

    </>
}