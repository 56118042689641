import { createIcons, icons } from "lucide";
import { createRef, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import '../../../assets/css/tabulator-edit.css';
import { useLoader } from "../../../base-components/Loader";
import Dialog from "../../../base-components/Headless/Dialog";
import Button from "../../../base-components/Button";
import Menu from "../../../base-components/Headless/Menu";
import Lucide from "../../../base-components/Lucide";
import * as xlsx from "xlsx";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/store";
import FormLabel from "../../../base-components/Form/FormLabel";
import Litepicker from "../../../base-components/Litepicker";
export default function Main() {


	const navigate = useNavigate()
	const { showLoader, hideLoader } = useLoader();
	const { groupid } = useParams();
	const tableRef = createRef<HTMLDivElement>();
	const tabulator = useRef<Tabulator>();
	const [from, setFrom] = useState('')
	const [to, setTo] = useState('')
	const [totPages, setTotPages] = useState(0)
	const [text, setText] = useState('')
	const dateRange1 = useSelector((state: RootState) => state.dateRange.dateRange ?? "");
	const [mode, setMode] = useState('0');
	const initClient = { chequeno: '', micRcode: '', transactionid: '', chequeDate: '' }

	
	const redrow = () => {
		if (tabulator.current) {
			tabulator.current.setData('/api/processing-fee-report', { from: from, to: to })
			//console.log('redraw')
		}
	}
	const initTabulator = () => {
		if (tableRef.current) {
			// console.log(groupid);
			tabulator.current = new Tabulator(tableRef.current, {
				ajaxFiltering: true,
				
				ajaxURL: `/api/processing-fee-report`, paginationMode: "remote", filterMode: "remote", sortMode: "remote", ajaxParams: { from: from, to: to },
				movableColumns: true, ajaxResponse: (_url, p, res) => {
					//console.log('hello');
					setTotPages(res.last_page)
					const page = p.page, size = p.size
					const processedData = res.data.map((row, index) => ({ ...row, srno: ((page - 1) * size) + index + 1, }));
					setText(res.sum);
					res.data = processedData
					return res
				}, //placeholderHeaderFilter:"ijn",
			

				pagination: true, paginationSize: 10, paginationSizeSelector: [10, 20, 30, 40], paginationButtonCount: 10,
				reactiveData: true, height: 'auto', layout: "fitColumns", placeholder: "No matching records found",
				columns: [
					{ title: "Sr.", field: "srno", print: true, hozAlign: "center", headerHozAlign: 'center', headerSort: false },
					{ title: "Member Name", field: "name", headerFilter: "input", minWidth: 200, headerFilterPlaceholder: "Search Name", print: true, headerSort: false, hozAlign: "center", headerHozAlign: 'center', visible: true },
					{ title: "Processing Fee", field: "amount", print: true, minWidth: 250, hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
					{ title: "Date of Collection", field: "date", minWidth: 150, print: true, hozAlign: "center", headerHozAlign: 'center', editor: "input", validator: "required", headerSort: false	},
					

				],
			});
		}

		tabulator.current?.on("renderComplete", () => { createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", }); });
	};
	// Redraw table onresize
	const reInitOnResizeWindow = () => {
		window.addEventListener("resize", () => {
			if (tabulator.current) {
				tabulator.current.redraw();
				createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", });
			}
		});
	};


	useEffect(() => {
		initTabulator()
		reInitOnResizeWindow()
		setTo('')
		setFrom('')
	}, [])
	useEffect(() => {
		console.log(mode);
	}, [mode])
	const onPrint = () => { if (tabulator.current) tabulator.current.print(); };
	const onExportPdf = () => {
		if (tabulator.current) {
			const doc = new jsPDF({ orientation: 'portrait', unit: 'px', format: 'a4' })
			doc.autoTable({
				head: [['Sr.',  'Member Name','Amount', 'collection Date', ]],
				body: tabulator.current.getData().map(row => [row.srno, row.name, row.amount, row.date, ]),
			});

			doc.save('ProcessingFeeReport.pdf')
		}
		// tabulator.current.download("pdf", "data.pdf",{ jsPDF,orientation: 'portrait', title: '', autoTable: { styles: { fillColor: [255, 0, 0] } } });
	};
	const onExportCsv = () => { if (tabulator.current) tabulator.current.download("csv", "ProcessingFeeReport.csv"); };
	const onExportJson = () => { if (tabulator.current) tabulator.current.download("json", "ProcessingFeeReport.json"); };
	const onExportHtml = () => { if (tabulator.current) tabulator.current.download("html", "ProcessingFeeReport.html", { style: true, }); };

	const onExportXlsx = () => {
		if (tabulator.current) {
			(window as any).XLSX = xlsx;
			tabulator.current.download("xlsx", "ProcessingFeeReport.xlsx", { sheetName: "Products", });
		}
	};
	return <>
		<div className="flex flex-col items-center mt-8 intro-y sm:flex-row"><h2 className="mr-auto text-lg font-medium">Processing Fee Report </h2>
			{/*<div className="flex w-full mt-4 sm:w-auto sm:mt-0">*/}
			{/*	<Button variant="primary" className="mr-2 shadow-md" onClick={() => navigate('/finance/expenses')} >Add Expenses</Button>*/}
			{/*</div>*/}
		</div>
		<div className="grid grid-cols-12 gap-4 gap-y-3">
			<div className="col-span-12 lg:col-span-5">
				<FormLabel htmlFor="modal-datepicker-1">From</FormLabel>
				<Litepicker
					id="modal-datepicker-1"
					value={from}
					onChange={setFrom}
					options={{
						autoApply: false, format: 'DD/MM/YYYY',
						showWeekNumbers: true,
						dropdowns: {
							minYear: 1990,
							maxYear: null,
							months: true,
							years: true,
						},
					}}
				/>
			</div>
			<div className="col-span-12 lg:col-span-5">
				<FormLabel htmlFor="modal-datepicker-2">To</FormLabel>
				<Litepicker
					id="modal-datepicker-2"
					value={to}
					onChange={setTo}
					options={{
						autoApply: false, format: 'DD/MM/YYYY',
						showWeekNumbers: true,
						dropdowns: {
							minYear: 1990,
							maxYear: null,
							months: true,
							years: true,
						},
					}}
				/>
			</div>
			<div className="col-span-12 lg:col-span-2 flex items-end">
				<Button className="mr-2 w-full" variant="primary" onClick={redrow}>Search</Button>
			</div>
		</div>
		<div className="p-5 mt-5 intro-y box">
			<div className="flex flex-col sm:flex-row sm:items-end xl:items-start">
				<div className="xl:flex sm:mr-auto">

					<Button id="tabulator-print" className="w-1/2 mr-2 sm:w-auto" variant="outline-secondary" onClick={onPrint}><Lucide icon="Printer" className="w-4 h-4 mr-2" />Print</Button>
					<Menu className="w-1/2 sm:w-auto">
						<Menu.Button as={Button} variant="outline-secondary" className="w-full sm:w-auto">
							<Lucide icon="FileText" className="w-4 h-4 mr-2" /> Export
							<Lucide icon="ChevronDown" className="w-4 h-4 ml-auto sm:ml-2" />
						</Menu.Button>
						<Menu.Items className="w-40">
							<Menu.Item onClick={onExportPdf}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export PDF</Menu.Item>
							<Menu.Item onClick={onExportCsv}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export CSV</Menu.Item>
							<Menu.Item onClick={onExportJson}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export JSON</Menu.Item>
							<Menu.Item onClick={onExportXlsx}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export XLSX</Menu.Item>
							<Menu.Item onClick={onExportHtml}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export HTML</Menu.Item>
						</Menu.Items>
					</Menu>
				</div>
				<Button className="mr-5" onClick={() => {

					if (tabulator.current) {
						
						setTo('')
						setFrom('')
						tabulator.current.setData()
					}

				}}><Lucide icon="RefreshCcw" className="w-4 h-4 mr-3" /></Button>
				<p>Total Processing Fee : {text}</p>
			</div>
			<div className="overflow-x-auto scrollbar-hidden">
				<div id="tabulator" ref={tableRef} className="mt-5"></div>
			</div>
			<div className='flex w-full'>
				<span className='ml-auto mr-10'><b>Total Pages: </b>{totPages}</span>
			</div>
		</div>
		

	</>
}