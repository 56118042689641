import { createIcons, icons } from "lucide";
import { createRef, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import '../../../assets/css/tabulator-edit.css';
import { useLoader } from "../../../base-components/Loader";
import Dialog from "../../../base-components/Headless/Dialog";
import Button from "../../../base-components/Button";
import Menu from "../../../base-components/Headless/Menu";
import Lucide from "../../../base-components/Lucide";
import * as xlsx from "xlsx";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/store";
import FormLabel from "../../../base-components/Form/FormLabel";

import Litepicker from "../../../base-components/Litepicker";
import FormSelect from "../../../base-components/Form/FormSelect";
import Tab from "../../../base-components/Headless/Tab";
export default function Main() {

	const navigate = useNavigate()
	const { showLoader, hideLoader } = useLoader();
	const { groupid } = useParams();
	const tableRef1 = createRef<HTMLDivElement>();
	const tableRef2 = createRef<HTMLDivElement>();
	const tabulator1 = useRef<Tabulator>();
	const tabulator2 = useRef<Tabulator>();
	const [from, setFrom] = useState('')
	const [to, setTo] = useState('')
	const [basicModalPreview, setBasicModalPreview] = useState(false)
	const [basicModalPreview1, setBasicModalPreview1] = useState(false)
	const [deleteModalPreview, setDeleteModalPreview] = useState(false)
	const [deleteModalPreview1, setDeleteModalPreview1] = useState(false)
	const [totPages1, setTotPages1] = useState(0)
	const [totPages2, setTotPages2] = useState(0)
	const [memberId, setMemberId] = useState('');
	const [emiMemberId, setEmiMemberId] = useState('');
	const [mode, setMode] = useState('0');
	const [areaName, setAreaName] = useState<any>()
	const [agentNameS, setAgentNameS] = useState<any>()
	const [areaId, setAreaId] = useState('')
	const [agentIdS, setAgentIdS] = useState('')

	const redrow = () => {
		if (tabulator1.current) {
			tabulator1.current.setData('/api/collection_approve', { from: from, to: to, agentId: agentIdS, areaId: areaId })
			//console.log('redraw')
		}
		if (tabulator2.current) {
			tabulator2.current.setData('/api/future-emi', { from: from, to: to, agentId: agentIdS, areaId: areaId })
			//console.log('redraw')
		}
	}
	useEffect(() => { }, [memberId])
		//loan approval first tab
		const initTabulator1 = () => {
			if (tableRef1.current) {
				// console.log(groupid);
				tabulator1.current = new Tabulator(tableRef1.current, {
					ajaxFiltering: true,

					ajaxURL: `/api/collection_approve`, paginationMode: "remote", filterMode: "remote", sortMode: "remote", ajaxParams: { from: from, to: to },
					movableColumns: true, ajaxResponse: (_url, p, res) => {
						//console.log('hello');
						setTotPages1(res.last_page)
						const page = p.page, size = p.size
						const processedData = res.data.map((row, index) => ({ ...row, srno: ((page - 1) * size) + index + 1, }));

						res.data = processedData
						return res
					},

					pagination: true, paginationSize: 10, paginationSizeSelector: [10, 20, 30, 40], paginationButtonCount: 10,
					reactiveData: true, height: 'auto', layout: "fitDataStretch", placeholder: "No matching records found",
					columns: [
						{ title: "Sr.", field: "srno", print: true, hozAlign: "center", headerHozAlign: 'center', headerSort: false },
						{ title: "Agent Name", field: "agent", minWidth: 200, print: true, hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
						{ title: "Member Name", field: "member", print: true, minWidth: 150, headerFilter: "input", headerFilterPlaceholder: "Search Name", hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
						{ title: "Area", field: "area", print: true, minWidth: 150, hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
						{
							title: "Date of Collection", field: "dateOfCollection", minWidth: 200, print: true, hozAlign: "center", headerHozAlign: 'center', validator: "required", headerSort: false,
							formatter: (cell) => {
								// Replace \n with <br> to show new lines
								return cell.getValue().replace(/\n/g, '<br>');
							},
							formatterParams: {
								html: true // Ensure that the formatter outputs HTML
							},
						},
						//{ title: "Installment Amount", field: "installmentAmount", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true },
						{ title: "Collected Amount", field: "collectedAmount", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
						{
							title: "Pendiing Installment", field: "pendingInstallment", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false,
							formatter: (cell) => {
								let value = cell.getValue();

								// Convert value to string if it's not already
								if (typeof value !== 'string') {
									value = String(value);
								}

								// Replace \n with <br> to show new lines
								return value.replace(/\n/g, '<br>');
							},
							formatterParams: {
								html: true // Ensure that the formatter outputs HTML
							}
						},
						{ title: "Tot Pending Amt", field: "totPenAmt", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
						//{ title: "Remaining Balance", field: "remainingBalance", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true },
						{ title: "Wallet Balance", field: "walletBalance", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },

						//{ title: "Agent", field: "agentName", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true },

						{
							title: 'Action', minWidth: 100, headerSort: false,
							formatter: () => '<button class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full">Approve</button>',
							cellClick: (e, cell) => {
								const planData = cell.getRow().getData();
								//setBasicModalPreview(true);
								//console.log(planData);
								setMemberId(planData.enqueryId);
								setDeleteModalPreview(true);
							},
						},

					],
				});
			}

			tabulator1.current?.on("renderComplete", () => { createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", }); });
	};

	//future loan collection approve tab 2
	const initTabulator2 = () => {
		if (tableRef2.current) {
			// console.log(groupid);
			tabulator2.current = new Tabulator(tableRef2.current, {
				ajaxFiltering: true,

				ajaxURL: `/api/future-emi`, paginationMode: "remote", filterMode: "remote", sortMode: "remote", ajaxParams: { from: from, to: to },
				movableColumns: true, ajaxResponse: (_url, p, res) => {
					//console.log('hello');
					setTotPages2(res.last_page)
					const page = p.page, size = p.size
					const processedData = res.data.map((row, index) => ({ ...row, srno: ((page - 1) * size) + index + 1, }));

					res.data = processedData
					return res
				},

				pagination: true, paginationSize: 10, paginationSizeSelector: [10, 20, 30, 40], paginationButtonCount: 10,
				reactiveData: true, height: 'auto', layout: "fitDataStretch", placeholder: "No matching records found",
				columns: [
					{ title: "Sr.", field: "srno", print: true, hozAlign: "center", headerHozAlign: 'center', headerSort: false },
					{ title: "Agent Name", field: "agent", minWidth: 200,  print: true, hozAlign: "center", headerHozAlign: 'center', visible: true },
					{ title: "Member Name", field: "member", print: true, minWidth: 150, headerFilter: "input", headerFilterPlaceholder: "Search Name", hozAlign: "center", headerHozAlign: 'center', visible: true },
					{ title: "Area", field: "area", print: true, minWidth: 150, hozAlign: "center", headerHozAlign: 'center', visible: true },
					//{
					//	title: "Date of Collection", field: "dateOfCollection", minWidth: 200, print: true, hozAlign: "center", headerHozAlign: 'center', validator: "required",
					//	formatter: (cell) => {
					//		// Replace \n with <br> to show new lines
					//		return cell.getValue().replace(/\n/g, '<br>');
					//	},
					//	formatterParams: {
					//		html: true // Ensure that the formatter outputs HTML
					//	},
					//},
					//{ title: "Installment Amount", field: "installmentAmount", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true },
					//{ title: "Collected Amount", field: "collectedAmount", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true },
					{
						title: "Pendiing Installment", field: "pendingInstallment", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true,
						formatter: (cell) => {
							let value = cell.getValue();

							// Convert value to string if it's not already
							if (typeof value !== 'string') {
								value = String(value);
							}

							// Replace \n with <br> to show new lines
							return value.replace(/\n/g, '<br>');
						},
						formatterParams: {
							html: true // Ensure that the formatter outputs HTML
						}
					},
					{ title: "Tot Pending Amt", field: "totPenAmt", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true },
					//{ title: "Remaining Balance", field: "remainingBalance", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true },
					{ title: "Wallet Balance", field: "walletBalance", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true },

					//{ title: "Agent", field: "agentName", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true },

					{
						title: 'Action', minWidth: 100,
						formatter: () => '<button class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full">Approve</button>',
						cellClick: (e, cell) => {
							const planData = cell.getRow().getData();
							//setBasicModalPreview(true);
							console.log(planData);
							setEmiMemberId(planData.id);
							setDeleteModalPreview1(true);
						},
					},

				],
			});
		}

		tabulator2.current?.on("renderComplete", () => { createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", }); });
	};
		// Redraw table onresize
		const reInitOnResizeWindow = () => {
			window.addEventListener("resize", () => {
				if (tabulator1.current) {
					tabulator1.current.redraw();
					createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", });
				}
				if (tabulator2.current) {
					tabulator2.current.redraw();
					createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", });
				}
			});
		};

		const approveCollection = async (id) => {
			try {
				const response = await fetch(`/api/approve-collection/${id}`, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
					},
				});

				if (response.ok) {
					const data = await response.json();
					setDeleteModalPreview(false);
					setBasicModalPreview(true);
					redrow();
					//console.log(data)
				} else {
					console.log('error')
				}
			} catch (error) {
				console.error('Error approving collection:', error);

			}
		};
		const futureEmi = async (id) => {
			try {
				const response = await fetch(`/api/future-emi-cut/${id}`, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
					},
				});

				if (response.ok) {
					const data = await response.json();
					setDeleteModalPreview1(false);
					setBasicModalPreview1(true);
					redrow();
					//console.log(data)
				} else {
					console.log('error')
				}
			} catch (error) {
				console.error('Error cutting emi:', error);

			}
		};


		useEffect(() => {
			initTabulator1()
			reInitOnResizeWindow()
			setTo('')
			setFrom('')
			getAgentName()
			getAreaName()
		}, [])
		async function getAgentName() {
			try {

				showLoader()
				const f = await fetch('/api/get-agent-name')
				if (f.ok) {
					const r = await f.json()
					if (r.e) alert(r.d)
					else {
						setAgentNameS(r.d)
						//console.log(agentName);
					}
				} else console.error(f.status, f.statusText)
			} catch (e) { console.error(e) } finally { hideLoader() }
		}
		async function getAreaName() {
			try {

				showLoader()
				const f = await fetch('/api/get-area-name')
				if (f.ok) {
					const r = await f.json()
					if (r.e) alert(r.d)
					else {
						setAreaName(r.d)
						//console.log(agentName);
					}
				} else console.error(f.status, f.statusText)
			} catch (e) { console.error(e) } finally { hideLoader() }
		}
		useEffect(() => {
			//console.log(mode);
		}, [mode])
		const onPrint = () => { if (tabulator1.current) tabulator1.current.print(); };
		const onExportPdf = () => {
			if (tabulator1.current) {
				const doc = new jsPDF({ orientation: 'landscape', unit: 'px', format: 'a4' })
				doc.autoTable({
					head: [['Sr.', 'AgentName', 'Area', 'Member Name', 'collection Date', 'collected Amount', 'pending Installment', 'Pending Amount','Wallete Balance']],
					body: tabulator1.current.getData().map(row => [row.srno, row.agent, row.member, row.area, row.dateOfCollection, row.collectedAmount, row.pendingInstallment, row.totPenAmt, row.walletBalance]),
				});

				doc.save('collection.pdf')
			}
			// tabulator.current.download("pdf", "data.pdf",{ jsPDF,orientation: 'portrait', title: '', autoTable: { styles: { fillColor: [255, 0, 0] } } });
		};
	const onExportCsv = () => { if (tabulator1.current) tabulator1.current.download("csv", "collection.csv"); };
	const onExportJson = () => { if (tabulator1.current) tabulator1.current.download("json", "collection.json"); };
	const onExportHtml = () => { if (tabulator1.current) tabulator1.current.download("html", "collection.html", { style: true, }); };

		const onExportXlsx = () => {
			if (tabulator1.current) {
				(window as any).XLSX = xlsx;
				tabulator1.current.download("xlsx", "collection.xlsx", { sheetName: "Products", });
			}
	};
	const handleTabChange = (index) => {
		if (index === 0) {
			initTabulator1();
		} else if (index === 1) {
			initTabulator2();
		}
	};
		return <>
			<div className="flex flex-col items-center mt-8 intro-y sm:flex-row"><h2 className="mr-auto text-lg font-medium">Collection List </h2>

			</div>
			<div className="grid grid-cols-12 gap-4 gap-y-3">
				<div className="col-span-12 lg:col-span-2">
					<FormLabel htmlFor="modal-datepicker-1">From</FormLabel>
					<div className="relative w-56 mx-auto">
						<div className="absolute flex items-center justify-center w-10 h-full border rounded-l bg-slate-100 text-slate-500 dark:bg-darkmode-700 dark:border-darkmode-800 dark:text-slate-400">
							<Lucide icon="Calendar" className="w-4 h-4" />
						</div>
						<Litepicker
							id="modal-datepicker-1"
							value={from}
							onChange={setFrom}
							options={{
								autoApply: false, format: 'DD/MM/YYYY',
								showWeekNumbers: true,
								dropdowns: {
									minYear: 1990,
									maxYear: null,
									months: true,
									years: true,
								},
							}}
							className="pl-12" />
					</div>

				</div>
				<div className="col-span-12 lg:col-span-2">
					<FormLabel htmlFor="modal-datepicker-2">To</FormLabel>
					<div className="relative w-56 mx-auto">
						<div className="absolute flex items-center justify-center w-10 h-full border rounded-l bg-slate-100 text-slate-500 dark:bg-darkmode-700 dark:border-darkmode-800 dark:text-slate-400">
							<Lucide icon="Calendar" className="w-4 h-4" />
						</div>
						<Litepicker
							id="modal-datepicker-2"
							value={to}
							onChange={setTo}
							options={{
								autoApply: false, format: 'DD/MM/YYYY',
								showWeekNumbers: true,
								dropdowns: {
									minYear: 1990,
									maxYear: null,
									months: true,
									years: true,
								},
							}}
							className="pl-12" />
					</div>

				</div>
				<div className="col-span-12 lg:col-span-3 lg:ml-9 lg:mt-7">
					<div className="input-form mb-5">
						{/*<FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row"> Agent Name</FormLabel>*/}

						<FormSelect id='sCat' onChange={(e) => setAgentIdS(e.currentTarget.value)} name='categoryId' value={agentIdS} aria-label="Default select example">
							<option value='0'>Select Agent</option>
							{(agentNameS != undefined && agentNameS && agentNameS.length > 0) ? agentNameS.map((cat: any) => (
								<option value={cat.id} key={cat.id}>{cat.name}</option>
							)) : <option>Nothing to show</option>}
						</FormSelect>
					</div>
				</div>
				
				<div className="col-span-12 lg:col-span-3 lg:mt-7">
					<div className="input-form mb-5">
						
						<FormSelect id='sCat' onChange={(e) => setAreaId(e.currentTarget.value)} name='categoryId' value={areaId} aria-label="Default select example">
							<option value='0'>Select Area</option>
							{(areaName != undefined && areaName && areaName.length > 0) ? areaName.map((cat: any) => (
								<option value={cat.id} key={cat.id}>{cat.name}</option>
							)) : <option>Nothing to show</option>}
						</FormSelect>
					</div>
				</div>
				<div className="col-span-12 lg:col-span-2 flex items-end mb-5">
					<Button className="mr-2 w-full" variant="primary" onClick={() => redrow()}>Search</Button>

				</div>
			</div>
			

			<Tab.Group onChange={handleTabChange}>
				<Tab.List variant="tabs">
					<Tab>
						<Tab.Button className="w-full py-2" as="button" onClick={initTabulator1}>
							Collection Approve
						</Tab.Button>
					</Tab>
					<Tab>
						<Tab.Button className="w-full py-2" as="button" onClick={initTabulator2}>
							Future EMI 
						</Tab.Button>
					</Tab>
				</Tab.List>
				<Tab.Panels className="border-b border-l border-r">
					<Tab.Panel className="p-5 leading-relaxed">
						<div className="p-5 mt-5 intro-y box">
							<div className="flex flex-col sm:flex-row sm:items-end xl:items-start">
								<div className="xl:flex sm:mr-auto">

									<Button id="tabulator-print" className="w-1/2 mr-2 sm:w-auto" variant="outline-secondary" onClick={onPrint}><Lucide icon="Printer" className="w-4 h-4 mr-2" />Print</Button>
									<Menu className="w-1/2 sm:w-auto">
										<Menu.Button as={Button} variant="outline-secondary" className="w-full sm:w-auto">
											<Lucide icon="FileText" className="w-4 h-4 mr-2" /> Export
											<Lucide icon="ChevronDown" className="w-4 h-4 ml-auto sm:ml-2" />
										</Menu.Button>
										<Menu.Items className="w-40">
											<Menu.Item onClick={onExportPdf}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export PDF</Menu.Item>
											<Menu.Item onClick={onExportCsv}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export CSV</Menu.Item>
											<Menu.Item onClick={onExportJson}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export JSON</Menu.Item>
											<Menu.Item onClick={onExportXlsx}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export XLSX</Menu.Item>
											<Menu.Item onClick={onExportHtml}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export HTML</Menu.Item>
										</Menu.Items>
									</Menu>
								</div>
								<Button onClick={() => {

									if (tabulator1.current) {
										tabulator1.current.setData()
										setTo('')
										setFrom('')
										setAgentIdS('')
										setAreaId('')
									}

								}}><Lucide icon="RefreshCcw" className="w-4 h-4 mr-3" /></Button>
							</div>
							<div className="overflow-x-auto scrollbar-hidden">
								<div id="tabulator" ref={tableRef1} className="mt-5"></div>
							</div>
							<div className='flex w-full'>
								<span className='ml-auto mr-10'><b>Total Pages: </b>{totPages1}</span>
							</div>
						</div>
					</Tab.Panel>
					<Tab.Panel className="p-5 leading-relaxed">
						<div className="p-5 mt-5 intro-y box">
							<div className="flex flex-col sm:flex-row sm:items-end xl:items-start">
								<div className="xl:flex sm:mr-auto">

									<Button id="tabulator-print" className="w-1/2 mr-2 sm:w-auto" variant="outline-secondary" onClick={onPrint}><Lucide icon="Printer" className="w-4 h-4 mr-2" />Print</Button>
									<Menu className="w-1/2 sm:w-auto">
										<Menu.Button as={Button} variant="outline-secondary" className="w-full sm:w-auto">
											<Lucide icon="FileText" className="w-4 h-4 mr-2" /> Export
											<Lucide icon="ChevronDown" className="w-4 h-4 ml-auto sm:ml-2" />
										</Menu.Button>
										<Menu.Items className="w-40">
											<Menu.Item onClick={onExportPdf}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export PDF</Menu.Item>
											<Menu.Item onClick={onExportCsv}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export CSV</Menu.Item>
											<Menu.Item onClick={onExportJson}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export JSON</Menu.Item>
											<Menu.Item onClick={onExportXlsx}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export XLSX</Menu.Item>
											<Menu.Item onClick={onExportHtml}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export HTML</Menu.Item>
										</Menu.Items>
									</Menu>
								</div>
								<Button onClick={() => {

									if (tabulator2.current) {
										tabulator2.current.setData()
										setTo('')
										setFrom('')
										setAgentIdS('')
										setAreaId('')
									}

								}}><Lucide icon="RefreshCcw" className="w-4 h-4 mr-3" /></Button>
							</div>
							<div className="overflow-x-auto scrollbar-hidden">
								<div id="tabulator" ref={tableRef2} className="mt-5"></div>
							</div>
							<div className='flex w-full'>
								<span className='ml-auto mr-10'><b>Total Pages: </b>{totPages2}</span>
							</div>
						</div>
					</Tab.Panel>
				</Tab.Panels>
			</Tab.Group>




			<Dialog open={deleteModalPreview} onClose={() => {
				setDeleteModalPreview(false);
			}}

			>
				<Dialog.Panel>
					<div className="p-5 text-center">
						<Lucide icon="CheckIcon" className="w-16 h-16 mx-auto mt-3 text-success" />
						<div className="mt-5 text-3xl">Are you sure?</div>
						<div className="mt-2 text-slate-500">
							Do you really want to Approve these records? <br />
							This process cannot be undone.
						</div>
					</div>
					<div className="px-5 pb-8 text-center">
						<Button type="button" variant="outline-secondary" onClick={() => {
							setDeleteModalPreview(false);
						}}
							className="w-24 mr-1"
						>
							Cancel
						</Button>
						<Button type="button" variant="success" className="w-24" onClick={() => {
							approveCollection(memberId);

						}} >
							Approve
						</Button>
					</div>
				</Dialog.Panel>
			</Dialog>
			
			<Dialog open={deleteModalPreview1} onClose={() => {
				setDeleteModalPreview1(false);
			}}

			>
				<Dialog.Panel>
					<div className="p-5 text-center">
						<Lucide icon="CheckIcon" className="w-16 h-16 mx-auto mt-3 text-success" />
						<div className="mt-5 text-3xl">Are you sure?</div>
						<div className="mt-2 text-slate-500">
							Do you really want to Approve these records? <br />
							This process cannot be undone.
						</div>
					</div>
					<div className="px-5 pb-8 text-center">
						<Button type="button" variant="outline-secondary" onClick={() => {
							setDeleteModalPreview1(false);
						}}
							className="w-24 mr-1"
						>
							Cancel
						</Button>
						<Button type="button" variant="success" className="w-24" onClick={() => {
							futureEmi(emiMemberId);

						}} >
							Approve
						</Button>
					</div>
				</Dialog.Panel>
			</Dialog>
			<Dialog open={basicModalPreview} onClose={() => { setBasicModalPreview(false); }} >

				<Dialog.Panel className="px-5 py-10">
					<div className="text-center">
						<div className="mb-5">Collection Approved successfully..!</div>
						<Button type="button" variant="primary" className="w-24 mr-2" onClick={() => {
							setBasicModalPreview(false);
						}}>Ok</Button>
					</div>
				</Dialog.Panel>
			</Dialog>
			<Dialog open={basicModalPreview1} onClose={() => { setBasicModalPreview1(false); }} >

				<Dialog.Panel className="px-5 py-10">
					<div className="text-center">
						<div className="mb-5">Emi Cut successfully..!</div>
						<Button type="button" variant="primary" className="w-24 mr-2" onClick={() => {
							setBasicModalPreview1(false);
						}}>Ok</Button>
					</div>
				</Dialog.Panel>
			</Dialog>

		</>
	}
