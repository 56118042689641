import { createIcons, icons } from "lucide";
import { createRef, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import '../../../assets/css/tabulator-edit.css';
import { useLoader } from "../../../base-components/Loader";
import Dialog from "../../../base-components/Headless/Dialog";
import Lucide from "../../../base-components/Lucide";
import Button from "../../../base-components/Button";
export default function Main() {
	const navigate = useNavigate()
	const { showLoader, hideLoader } = useLoader()
	const tableRef = createRef<HTMLDivElement>()
	const tabulator = useRef<Tabulator>()

const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
const [id, setId] = useState(0);
const deleteButtonRef = useRef(null);
	//const sendButtonRef = useRef();

	const [customers, setCustomers] = useState([])

	//const [headerFooterModalPreview, setHeaderFooterModalPreview] = useState(false)
	//const [successModalPreview, setSuccessModalPreview] = useState(false)
	//const [modal, setModal] = useState({ msg: '', title: '', type: '' })

	//const editedCallback = (cell: CellComponent) => {
	//	const d = cell.getData() as any
	//	const name = d.name
	//}

	var copyIcon = function () { return '<i data-lucide="bell-plus" className="w-8 h-8 mr-1 text-success"></i> Remind'; };

	const initTabulator = () => {
		if (tableRef.current) {
			tabulator.current = new Tabulator(tableRef.current, {
				data: customers,
				movableColumns: true,
				pagination: true,
				paginationSize: 10,
				paginationSizeSelector: [10, 20, 30, 40],
				paginationButtonCount: 10,
				reactiveData: true,
				height: 'auto',
				layout: "fitDataStretch",
				placeholder: "No matching records found",
				columns: [
					{ title: "Sr.", field: "srno", minWidth: 30, hozAlign: "center", headerHozAlign: 'center', headerSort: false },
					{ title: "Plan Name", field: "name", minWidth: 170, hozAlign: "center", headerHozAlign: 'center', validator: "required", headerSort: false },
					{ title: "Amount", field: "amount", minWidth: 120, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "input", validator: "required", headerSort: false },
					{ title: "Installment", field: "installation", minWidth: 100, hozAlign: "center", headerHozAlign: 'center' },
					{ title: "Duration", field: "duration", minWidth: 100, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "input", validator: "required", headerSort: false },
					{ title: "Interest", field: "interest", minWidth: 100, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "input", validator: "required", headerSort: false },
					{ title: "Processing Fee", field: "processingFee", minWidth: 150, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "input", validator: "required", headerSort: false },
					{ title: "Frequency", field: "frequency", minWidth: 150, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "input", validator: "required", headerSort: false },
					//{
					//	title: "Reminder", formatter: copyIcon, headerSort: false, hozAlign: "center", width: 100,
					//	cellClick: function (_e, row) {
					//		// Handle reminder click
					//	}
					//},
					{
						title: 'Edit', minWidth: 100, headerSort: false,
						formatter: () => '<i data-lucide="edit" class="w-8 h-8 mr-1 text-success"></i>',
						cellClick: (e, cell) => {
							const planData = cell.getRow().getData();
							
							navigate(`/finance/plan-creation/${planData.id}`);
						},
					},
					{
						title: 'Delete', minWidth: 100, headerSort: false,
						formatter: () => '<i data-lucide="trash2" class="w-8 h-8 mr-1 text-danger"></i>',
						cellClick: (e, cell) => {
							const planData = cell.getRow().getData();
							setId(planData.id);
							setDeleteConfirmationModal(true);
						},
					},
				],
			
			});

			tabulator.current?.on("renderComplete", () => {
				createIcons({ icons, attrs: { "stroke-width": 1.5 }, nameAttr: "data-lucide" });
			});
		}
	};


	// Redraw table onresize
	const reInitOnResizeWindow = () => {
		window.addEventListener("resize", () => {
			if (tabulator.current) {
				tabulator.current.redraw();
				createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", });
			}
		});
	};

	const loadData = async () => {
		try {
			showLoader()
			const f = await fetch('/api/plan-list')
			if (f.ok) {
				const d = await f.json();
				d.customers.forEach((customer: any, i: number) => { customer.srno = i + 1 })
				setCustomers(d.customers)
			} else console.error(f.status, f.statusText)
		} catch (e) { } finally { hideLoader() }
	}

	async function deleteprocess() {
		try {
			showLoader()//body: JSON.stringify(process), 
			const f = await fetch(`/api/plan-list/${id}`, { method: 'DELETE', headers: { 'Content-Type': 'application/json', }, })
			if (f.ok) {
				const j = await f.json()
				if (j.e) { }
				else {
					loadData();
					setDeleteConfirmationModal(false);

					}
				
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	useEffect(() => {
		loadData();
	}, []);

	useEffect(() => {
		if (tabulator.current) {
			tabulator.current.replaceData(customers);
		} else {
			initTabulator();
		}
		reInitOnResizeWindow();
	}, [customers]);

	return <>
		<div className="flex flex-col items-center mt-8 intro-y sm:flex-row">
			<h2 className="mr-auto text-lg font-medium">Plan List</h2>
			<div className="flex w-full mt-4 sm:w-auto sm:mt-0">
				<Button variant="primary" className="mr-2 shadow-md" onClick={() => navigate('/finance/plan-creation')} >Add Plan</Button>
			</div>
		</div>
		<div className="p-5 mt-5 intro-y box">
			<div className="overflow-x-auto scrollbar-hidden">
				<div id="tabulator" ref={tableRef} className="mt-5"></div>
			</div>
		</div>
		<Dialog open={deleteConfirmationModal} initialFocus={deleteButtonRef} onClose={() => { setDeleteConfirmationModal(false); }}>
			<Dialog.Panel>
				<div className="p-5 text-center">
					<Lucide icon="Trash2" className="w-16 h-16 mx-auto mt-3 text-danger" />
					<div className="mt-5 text-3xl">Are you sure?</div>
					<div className="mt-2 text-slate-500">
						Delete ? <br />
						This process cannot be undone.
					</div>
				</div>
				<div className="px-5 pb-8 text-center">
					<Button variant="outline-secondary" onClick={() => { setDeleteConfirmationModal(false); }} className="w-24 mr-1">Cancel</Button>
					<Button variant="danger" className="w-24" ref={deleteButtonRef} onClick={deleteprocess} >Delete</Button>
				</div>
			</Dialog.Panel>
		</Dialog>
		{/*
			<Dialog open={headerFooterModalPreview} onClose={() => { setHeaderFooterModalPreview(false); }} initialFocus={sendButtonRef}>
			<Dialog.Panel>
				<Dialog.Title><h2 className="mr-auto text-base font-medium">Add Customer</h2></Dialog.Title>
				<Dialog.Description className="grid grid-cols-12 gap-4 gap-y-3">
					<div className="col-span-6 sm:col-span-6">
						<FormLabel>Customer Name</FormLabel>
						<FormInput type="text" placeholder="Customer Name" onChange={(e) => setCategory(e.target.value)} />
					</div>
					<div className="col-span-6 sm:col-span-6">
						<FormLabel>Mobile</FormLabel>
						<FormInput type="text" placeholder="Mobile" onChange={(e) => setCategory(e.target.value)} />
					</div>
					<div className="col-span-12 sm:col-span-12">
						<FormLabel>Address</FormLabel>
						<FormTextarea placeholder="Address" onChange={(e) => setCategory(e.target.value)} />
					</div>
					<div className="col-span-12 sm:col-span-12">
						<FormLabel>Menu</FormLabel>
						<FormTextarea placeholder="Menu" onChange={(e) => setCategory(e.target.value)} />
					</div>
				</Dialog.Description>
				<Dialog.Footer>
					<Button type="button" variant="outline-secondary" onClick={() => { setHeaderFooterModalPreview(false); }} className="w-20 mr-1">Cancel</Button>
					<Button variant="primary" type="button" ref={sendButtonRef} onClick={saveCategory}>Add Maharaj</Button>
				</Dialog.Footer>
			</Dialog.Panel>
		</Dialog>
		<Dialog open={successModalPreview} onClose={() => { setSuccessModalPreview(false); }} >
			<Dialog.Panel>
				<div className="p-5 text-center">
					<Lucide icon="CheckCircle" className={`w-16 h-16 mx-auto mt-3 text-${modal.type}`} />
					<div className="mt-5 text-3xl">{modal.title}</div>
					<div className="mt-2 text-slate-500">{modal.msg}</div>
				</div>
				<div className="px-5 pb-8 text-center">
					<Button type="button" variant="primary" onClick={() => { setSuccessModalPreview(false); }} className="w-24" > Ok </Button>
				</div>
			</Dialog.Panel>
		</Dialog>
		*/}

	</>
}