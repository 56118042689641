import React, { useEffect, useState } from 'react';


const FullDetailsComponent = ({ id }) => {
    const [guarantors, setGuarantors] = useState<any>(null);
    async function getGuranter() {
        try {

            //showLoader()
           // console.log({ id });
            const f = await fetch(`/api/view-details/${id}`)
            if (f.ok) {
                const r = await f.json()
                if (r.e) alert(r.d)
                else {
                    //  setExpenseName(r.d)
                    setGuarantors(r.d)
                    //console.log(r.d);
                }
            } else console.error(f.status, f.statusText)
        } catch (e) { console.error(e) } finally { }
    }
    useEffect(() => { getGuranter(); }, [])
    return (<>
        <div className="max-w-lg mx-auto p-6 border rounded-lg shadow-lg bg-white">
            {guarantors ? (
                <>
                    <h3 className="text-center text-2xl font-semibold text-gray-800 mb-6">Guarantor Details</h3>
                    <div className="flex flex-col">
                        {Object.entries({
                            Name: guarantors.name,
                            Mobile: guarantors.mobile,
                            Address: guarantors.address,
                            'Relative Mobile': guarantors.relativeMobile,
                            Reason: guarantors.reason,
                            'Return Installment': guarantors.returnInstallment,
                            'Other Loan': guarantors.otherLoan,
                            'CIBIL Score': guarantors.cibilScore,
                            'Family Income': guarantors.familyIncome,
                            'Yearly Income': guarantors.yearlyIncome,
                            'Own House': guarantors.ownHouse ? 'Yes' : 'No',
                            'Own Business': guarantors.ownBusiness ? 'Yes' : 'No',
                            'Total Capital': guarantors.totalCapital,
                        }).map(([label, value]) => (
                            <div className="flex justify-between py-2 border-b border-gray-200" key={label}>
                                <strong className="text-gray-700">{label}:</strong>
                                <span className="text-gray-600">{value}</span>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <p className="text-center text-gray-500">Data not filled up</p>
            )}
        </div>

    </>
    );
};

export default FullDetailsComponent;
