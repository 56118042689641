import { useEffect, useState ,useRef} from "react"
import Button from "../../../base-components/Button"
import { FormInline, FormInput, FormTextarea, InputGroup } from "../../../base-components/Form"
import Menu from "../../../base-components/Headless/Menu"
import Lucide from "../../../base-components/Lucide"
import Table from "../../../base-components/Table"
import Dialog from "../../../base-components/Headless/Dialog"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootState } from "../../../stores/store"
import { useLoader } from "../../../base-components/Loader"
import html2pdf from "html2pdf.js";
type Head = { date: string, eventName: string, eventAddress: string, clientName: string, clientMobile: string }
type Quotation = { EventId: number, events: Events[], charges: Additional[], foodCost: number, discount: number }
type Events = { name: string, dishes: Dish[], details: Details }
type Dish = { title: string, contents: string }
type Details = { people: number, date: string, type: string, cost: number, }
type Additional = { title: string, description: string, price: number, }

export default function Main() {
	const { showLoader, hideLoader } = useLoader()
	const { groupid } = useParams();
	const nav = useNavigate()
	const eventId = useSelector((state: RootState) => state.eventId.eventId)
	
	

	const [waNumber, setWaNumber] = useState('')
	const [newEventModalPreview, setnewEventModalPreview] = useState(false)
	const [sendWAModalOpen, setSendWAModalOpen] = useState(false)

	const [head, setHead] = useState<Head>()
	const [title, setTitle] = useState('')
	const [letterInfo, setLetterInfo] = useState<any>()
	const [price, setPrice] = useState(0)
	const letterRef = useRef<HTMLDivElement>();
	const [events, setEvents] = useState<Quotation>()

	const handlePrint = () => {
		const printContent = letterRef.current;
		const originalContent = document.body.innerHTML;

		// Set the body's HTML to only the printContent
		document.body.innerHTML = printContent.innerHTML;

		// Trigger the print dialog
		window.print();

		// Restore the original HTML after printing
		document.body.innerHTML = originalContent;

		// After restoring, React will re-render the original content
		window.location.reload(); // Ensures React's virtual DOM syncs correctly after reverting the document.body
	};
	useEffect(() => { },[letterInfo])
	async function getCategories() {
		try {


			const f = await fetch(`/api/letter-info/${groupid}`)
			if (f.ok) {
				const r = await f.json()
				if (r.e) alert(r.d)
				else {
					setLetterInfo(r.d)
					console.log(r.d)
					console.log(letterInfo);
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
	const generatePDF = () => {
		const element = letterRef.current;
		const opt = {
			
			filename: 'sanction-letter.pdf',
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 2 },
			jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
		};
		html2pdf().from(element).set(opt).save();
	};
	const sendWAQuotation = async function () {
		try {
			showLoader()
			const f = await fetch('/api/quotation_wa', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify({ ...events, mobile: waNumber }), })
			if (f.ok) {
				const x = await f.json()
				if (x.e) console.error(x)
				else { alert(x.msg) }
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	useEffect(() => { getCategories() },[])

	return <>
		<div className="flex flex-col items-center mt-8 intro-y sm:flex-row mb-5">
			<h2 className="mr-auto text-lg font-medium">Sanction Letter</h2>
			<div className="flex w-full mt-4 sm:w-auto sm:mt-0">
				<Button variant="primary" className="mr-2 shadow-md" onClick={() => { handlePrint() }} ><Lucide icon='PrinterCheck' className='mr-2' />Print</Button>
				<Button variant="primary" className="mr-2 shadow-md" onClick={() => { setSendWAModalOpen(true) }} ><Lucide icon='Send' className='mr-2' />Send To WhatsApp</Button>
				<Menu className="ml-auto sm:ml-0">
					<Menu.Button as={Button} className="px-2 !box">
						<span className="flex items-center justify-center w-5 h-5"><Lucide icon="Plus" className="w-4 h-4" /></span>
					</Menu.Button>
					<Menu.Items className="w-40">
						{/*<Menu.Item onClick={loadPdf1}><Lucide icon="File" className="w-4 h-4 mr-2" /> Export Word</Menu.Item>*/}
						<Menu.Item onClick={generatePDF}><Lucide icon="File" className="w-4 h-4 mr-2" /> Export PDF</Menu.Item>
					</Menu.Items>
				</Menu>
			</div>
		</div>
		{letterInfo &&
		<div ref={letterRef} className="max-w-4xl mx-auto p-8 bg-white shadow-md">
			<div className="text-center mb-8">
				<h1 className="text-4xl font-bold text-blue-800">Microfinance</h1>
				
				<div className="mt-2 text-gray-600">
					<p className="text-md">Registered Office:Sangli, Tal- Miraj, Dist-Sangli</p>
						<p className="text-md">Phone: +91 9511726780 | Email: sanglisoftware@gmail.com</p>
				</div>
			</div>
			<hr className="mt-6 border-t-2 border-gray-300 mb-5"  />
			<div className="text-right">
				<p className="text-sm mb-4">{letterInfo.date}</p>
			</div>

			<div className="mb-6">
				<p>{letterInfo.name}</p>
				<p>{letterInfo.adress}</p>
				
			</div>

			<div className="mb-6">
				<p>Dear {letterInfo.name},</p>
			</div>

			<div className="mb-6">
				<p className="font-bold">Subject: Sanction Letter for Personal Loan</p>
			</div>

			<div className="mb-6">
				<p>
					We are pleased to inform you that your application for a Personal Loan with Rajyog has been thoroughly reviewed, and we are delighted to grant approval for the requested loan to meet your personal financial needs.
				</p>
			</div>

			<div className="mb-6">
				<p className="mb-4">The details of the sanctioned personal loan are as follows:</p>
				<table className="min-w-full table-auto border-collapse border border-gray-300">
					<tbody>
						<tr className="bg-gray-100">
							<td className="px-4 py-2 font-semibold border border-gray-300">Loan Amount</td>
							<td className="px-4 py-2 border border-gray-300">{letterInfo.loanAmount}</td>
						</tr>
						<tr>
							<td className="px-4 py-2 font-semibold border border-gray-300">EMI</td>
							<td className="px-4 py-2 border border-gray-300">{letterInfo.emi}</td>
						</tr>
						<tr className="bg-gray-100">
							<td className="px-4 py-2 font-semibold border border-gray-300">Frequency</td>
							<td className="px-4 py-2 border border-gray-300">{letterInfo.frequency}</td>
						</tr>
						<tr>
							<td className="px-4 py-2 font-semibold border border-gray-300">Duration</td>
							<td className="px-4 py-2 border border-gray-300">{letterInfo.duration}</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div className="mb-6">
				<p>
					Please carefully review the enclosed personal loan agreement, which provides comprehensive details about the terms and conditions of the loan. If you have any questions or require clarification on any aspect of the personal loan, do not hesitate to contact our personal loan department at jaysingpur.
				</p>
			</div>

			
			<div className="mb-6">
				<p>
					We appreciate your choice in rajyog, and we look forward to providing you with the financial support you need. Congratulations on the sanction of your personal loan, and we wish you every success in achieving your financial goals.
				</p>
			</div>

			<div className="mb-6">
				<p>Sincerely,</p>
				<p>Admin</p>
				<p>CEO</p>
				<p>Microfinance</p>
				<p>9511726780</p>
			</div>
			</div>}
		
		<Dialog open={sendWAModalOpen} onClose={() => { setSendWAModalOpen(false) }}>
			<Dialog.Panel>
				<Dialog.Title>
					<h2 className="mr-auto text-base font-medium">Share Quotation on WhatsApp</h2>
					<Button className="w-24 ml-auto" onClick={() => { setSendWAModalOpen(false) }}><Lucide icon='XCircle' /></Button>
				</Dialog.Title>
				<div className="mx-5 my-3">
					<label className='font-medium'>WhatsApp Mobile Number:</label>
					<FormInput placeholder='WhatsApp Mobile Number' className='mb-5' onChange={(e) => setWaNumber(e.target.value)} value={waNumber} maxLength={10} />
				</div>
				<Dialog.Footer className='mt-1'>
					<Button type="button" variant="primary" className=" mr-2"
						onClick={() => {
							sendWAQuotation()
							setSendWAModalOpen(false)
						}}><Lucide icon="Send" className="mr-2" /> Send Quotation on WhatsApp</Button>
				</Dialog.Footer>
			</Dialog.Panel>
		</Dialog>
	</>

	//async function showQuotationDemo() {
	//	try {
	//		showLoader()
	//		const f = await fetch('/api/quotation_demo', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(''), })
	//		if (f.ok) {
	//			const x = await f.json()
	//			if (x.e) console.error(x)
	//			else { window.open(x.path) }
	//		} else console.error(f.status, f.statusText)
	//	} catch (e) { console.error(e) } finally { hideLoader() }
	//}
}