import { createIcons, icons } from "lucide";
import { createRef, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import '../../../assets/css/tabulator-edit.css';
import { useLoader } from "../../../base-components/Loader";
import Dialog from "../../../base-components/Headless/Dialog";
import Button from "../../../base-components/Button";
import Menu from "../../../base-components/Headless/Menu";
import Lucide from "../../../base-components/Lucide";
import * as xlsx from "xlsx";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/store";
import FormLabel from "../../../base-components/Form/FormLabel";
import FormSelect from "../../../base-components/Form/FormSelect";
import FormInput from "../../../base-components/Form/FormInput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import clsx from "clsx";
import Litepicker from "../../../base-components/Litepicker";

export default function Main() {

	const navigate = useNavigate()
	const { showLoader, hideLoader } = useLoader();
	const { groupid } = useParams();
	const tableRef = createRef<HTMLDivElement>();
	const tabulator = useRef<Tabulator>();

	const [modal, setModal] = useState({ msg: '', title: '', type: '' });
	const [basicModalPreview, setBasicModalPreview] = useState(false)
	const [detailsShow, setDetailsShow] = useState(false)
	const [totPages, setTotPages] = useState(0)
	const [planType, setPlanType] = useState<any>()
	const [areaName, setAreaName] = useState<any>()
	const [planDetails, setPlanDetails] = useState<any>()
	const dateRange1 = useSelector((state: RootState) => state.dateRange.dateRange ?? "");
	const [agentName, setAgentName] = useState<any>();
	const [dateRange, setDateRange] = useState(dateRange1);
	//const [chequeDate, setChequeDate] = useState(dateRange1);
	const [agentId, setAgentId] = useState('');
	const [areaId, setAreaId] = useState('');
	const [paid, setPaid] = useState('');
	const [unpaid, setUnpaid] = useState('');
	const [mode, setMode] = useState(false);
	const initClient = { chequeno: '', micRcode: '', transactionid: '', chequeDate: '' }
	const [agent, setAgent] = useState(initClient)
	//const sendButtonRef = useRef();
	const [from, setFrom] = useState('')
	const [to, setTo] = useState('')
	const [customers, setCustomers] = useState([])

	useEffect(() => { }, [agentId])
	useEffect(() => { }, [areaId])

	
	var copyIcon = function () { return '<i data-lucide="bell-plus" className="w-8 h-8 mr-1 text-success"></i> Remind'; };
	const redrow = () => {
		if (tabulator.current) {
			tabulator.current.setData('/api/loan-recovery-report', { from: from, to: to, agentId: agentId, areaId: areaId })
			//console.log('redraw')
		}
		//initTabulator()
		//reInitOnResizeWindow()
	}
	const initTabulator = () => {
		if (tableRef.current) {
			// console.log(groupid);
			tabulator.current = new Tabulator(tableRef.current, {
				ajaxFiltering: true,

				ajaxURL: `/api/loan-recovery-report`, paginationMode: "remote", filterMode: "remote", sortMode: "remote",
				//ajaxParams: { from: from, to: to, agentId},
				movableColumns: true, ajaxResponse: (_url, p, res) => {
					
					setTotPages(res.last_page)
					const page = p.page, size = p.size
					const processedData = res.data.map((row, index) => ({ ...row, srno: ((page - 1) * size) + index + 1, }));
					setPaid(res.paidrec)
					setUnpaid(res.pendingrec)
					res.data = processedData
					return res
				}, //placeholderHeaderFilter:"ijn",

				pagination: true, paginationSize: 10, paginationSizeSelector: [10, 20, 30, 40], paginationButtonCount: 10,
				reactiveData: true, height: 'auto', layout: "fitDataStretch", placeholder: "No matching records found",
				columns: [
					{ title: "Sr.", field: "srno", minWidth: 30, print: true, hozAlign: "center", headerHozAlign: 'center', headerSort: false },
					{ title: "Name", field: "name", minWidth: 150, print: true, hozAlign: "center", headerHozAlign: 'center', validator: "required", headerSort: false },
					{ title: "Mobile", field: "mobile", minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "input", validator: "required", headerSort: false },
					{ title: "Area", field: "area", minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center', headerSort: false },
					{ title: "Agent Name", field: "agentName", minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center', editor: "input", validator: "required", headerSort: false },
					{ title: "Loan Amount", field: "loanAmt", minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center', validator: "required", headerSort: false },
					{ title: "Disbursed Date", field: "disburseDate", minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center', validator: "required", headerSort: false },
					{ title: "Installment Amt", field: "installmentAmount", minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center', validator: "required", headerSort: false },
					{ title: "No of Installment", field: "noOfInstallment", minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center', validator: "required", headerSort: false },
					{ title: "NO. Paid Installment", field: "noPaidInstallment", minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center', validator: "required", headerSort: false },
					{ title: "Paid Installment Amount", field: "paidInstallmentAmt", minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center', validator: "required", headerSort: false },
					{ title: "No. Unpaid Installment", field: "noUnpaidInstallment", minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center', validator: "required", headerSort: false },
					{ title: "Unpaid Installment Amount", field: "unpaidInstallmentAmt", minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center', validator: "required", headerSort: false },
					{ title: "Loan Nill Status", field: "nillStatus", minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center', validator: "required", headerSort: false },
					{
						title: 'EMI Info', minWidth: 100, headerSort: false,
						formatter: () => '<button class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full">Info</button>',
						cellClick: (e, cell) => {
							const planData = cell.getRow().getData();

							navigate(`/finance/user-list/${planData.id}`);
						},
					},
				],
			});
		}

		tabulator.current?.on("renderComplete", () => { createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", }); });
	};
	

	// Redraw table onresize
	const reInitOnResizeWindow = () => {
		window.addEventListener("resize", () => {
			if (tabulator.current) {
				tabulator.current.redraw();
				createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", });
			}
		});
	};

	async function getAgentName() {
		try {

			showLoader()
			const f = await fetch('/api/get-agent-name')
			if (f.ok) {
				const r = await f.json()
				if (r.e) alert(r.d)
				else {
					setAgentName(r.d)
					//console.log(agentName);
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
	async function getAreaName() {
		try {

			showLoader()
			const f = await fetch('/api/get-area-name')
			if (f.ok) {
				const r = await f.json()
				if (r.e) alert(r.d)
				else {
					setAreaName(r.d)
					//console.log(agentName);
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}


	
	useEffect(() => {
		initTabulator()
		reInitOnResizeWindow()
		getAgentName()
		getAreaName()
		setTo('')
		setFrom('')
	}, [])
	const onPrint = () => { if (tabulator.current) tabulator.current.print(); };
	//const onExportPdf = () => {
	//	if (tabulator.current) {
	//		const doc = new jsPDF({ orientation: 'portrait', unit: 'px', format: 'a4' })
	//		doc.autoTable({
	//			head: [['Sr.', 'Name', 'Group Name', 'Date', 'Disburment Amount', 'Installment Amount', 'Duration', 'Frequency', 'Processing Fee', 'Agent']],
	//			body: tabulator.current.getData().map(row => [row.srno, row.name, row.groupName, row.date, row.disburseAmount, row.installmentAmount, row.duration, row.frequency, row.processingFee, row.agentName]),
	//		});

	//		doc.save('Expenses.pdf')
	//	}
	//	// tabulator.current.download("pdf", "data.pdf",{ jsPDF,orientation: 'portrait', title: '', autoTable: { styles: { fillColor: [255, 0, 0] } } });
	//};
	const onExportPdf = () => {
		if (tabulator.current) {
			const doc = new jsPDF({ orientation: 'landscape', unit: 'px', format: 'a4' });

			doc.autoTable({
				head: [['Sr.', 'Name', 'Mobile', 'Area', 'Agent Name', 'Loan Amount', 'Disbursed Date', 'Installment Amount', 'Installment', 'Paid Installment', 'Paid Installment Amount', 'Unpaid Installment', 'unpaid Installment Amount', 'Nill Status']],
				body: tabulator.current.getData().map(row => [
					row.srno,
					row.name,
					row.mobile,
					row.area,
					row.agentName,
					row.loanAmt,
					row.disburseDate,
					row.installmentAmount,
					
					row.noOfInstallment,
					row.noPaidInstallment,
					row.paidInstallmentAmt,
					row.noUnpaidInstallment,
					row.unpaidInstallmentAmt,
					row.nillStatus
				]),
				margin: { top: 20, left: 20, right: 20, bottom: 20 }, // Adjust margins
				styles: {
					fontSize: 8, // Decrease font size to fit more data
					overflow: 'linebreak', // Handle long text overflow
				},
				columnStyles: {
					 // Adjust specific column widths if needed
					5: { cellWidth: 30 },
					6: { cellWidth: 50 },
					7: { cellWidth: 40 },
					8: { cellWidth: 40 },
					9: { cellWidth: 40 },
					10: { cellWidth: 50 },
					11: { cellWidth: 50 },
					12: { cellWidth: 50 },
					// Add more column styles if needed
				},
				tableWidth: 'wrap', // Fit the table to the page
			});

			doc.save('LoanRecoveryReport.pdf');
		}
	};
	const onExportCsv = () => { if (tabulator.current) tabulator.current.download("csv", "LoanRecoveryReport.csv"); };
	const onExportJson = () => { if (tabulator.current) tabulator.current.download("json", "LoanRecoveryReport.json"); };
	const onExportHtml = () => { if (tabulator.current) tabulator.current.download("html", "LoanRecoveryReport.html", { style: true, }); };

	const onExportXlsx = () => {
		if (tabulator.current) {
			(window as any).XLSX = xlsx;
			tabulator.current.download("xlsx", "LoanRecoveryReport.xlsx", { sheetName: "Products", });
		}
	};
	return <>
		<div className="flex flex-col items-center mt-8 intro-y sm:flex-row"><h2 className="mr-auto text-lg font-medium">Loan Recovery Report</h2>
			{/*<div className="flex w-full mt-4 sm:w-auto sm:mt-0">*/}
			{/*	<Button variant="primary" className="mr-2 shadow-md" onClick={() => navigate('/finance/expenses')} >Add Expenses</Button>*/}
			{/*</div>*/}
		</div>
		<div className="grid grid-cols-12 gap-4 gap-y-3">
			<div className="col-span-12 lg:col-span-2">
				<FormLabel htmlFor="modal-datepicker-1">From</FormLabel>
				<div className="relative w-56 mx-auto">
					<div className="absolute flex items-center justify-center w-10 h-full border rounded-l bg-slate-100 text-slate-500 dark:bg-darkmode-700 dark:border-darkmode-800 dark:text-slate-400">
						<Lucide icon="Calendar" className="w-4 h-4" />
					</div>
					<Litepicker
						id="modal-datepicker-1"
						value={from}
						onChange={setFrom}
						options={{
							autoApply: false, format: 'DD/MM/YYYY',
							showWeekNumbers: true,
							dropdowns: {
								minYear: 1990,
								maxYear: null,
								months: true,
								years: true,
							},
						}}
						className="pl-12" />
				</div>

			</div>
			<div className="col-span-12 lg:col-span-2">
				<FormLabel htmlFor="modal-datepicker-2">To</FormLabel>
				<div className="relative w-56 mx-auto">
					<div className="absolute flex items-center justify-center w-10 h-full border rounded-l bg-slate-100 text-slate-500 dark:bg-darkmode-700 dark:border-darkmode-800 dark:text-slate-400">
						<Lucide icon="Calendar" className="w-4 h-4" />
					</div>
					<Litepicker
						id="modal-datepicker-2"
						value={to}
						onChange={setTo}
						autoComplete='off'
						options={{
							
							autoApply: false, format: 'DD/MM/YYYY',
							showWeekNumbers: true,
							dropdowns: {
								minYear: 1990,
								maxYear: null,
								months: true,
								years: true,
							},
						}}
						className="pl-12" />
				</div>

			</div>
			<div className="col-span-12 lg:col-span-3 lg:ml-9 lg:mt-7">
				<div className="input-form mb-5">
					
					<FormSelect id='sCat' onChange={(e) => setAgentId(e.currentTarget.value)} name='categoryId' value={agentId} aria-label="Default select example">
						<option value='0'>Select Agent</option>
						{(agentName != undefined && agentName && agentName.length > 0) ? agentName.map((cat: any) => (
							<option value={cat.id} key={cat.id}>{cat.name}</option>
						)) : <option>Nothing to show</option>}
					</FormSelect>
				</div>
			</div>
			
			<div className="col-span-12 lg:col-span-3 lg:mt-7">
				<div className="input-form mb-5">
					
					<FormSelect id='sCat' onChange={(e) => setAreaId(e.currentTarget.value)} name='categoryId' value={areaId} aria-label="Default select example">
						<option value='0'>Select Area</option>
						{(areaName != undefined && areaName && areaName.length > 0) ? areaName.map((cat: any) => (
							<option value={cat.id} key={cat.id}>{cat.name}</option>
						)) : <option>Nothing to show</option>}
					</FormSelect>
				</div>
			</div>
			<div className="col-span-12 lg:col-span-2 flex items-end mb-5">
				<Button className="mr-2 w-full" variant="primary" onClick={()=>redrow()}>Search</Button>
			</div>
		</div>
		
		<div className="p-5 mt-5 intro-y box">
			<div className="flex flex-col sm:flex-row sm:items-end xl:items-start">
				<div className="xl:flex sm:mr-auto">

					<Button id="tabulator-print" className="w-1/2 mr-2 sm:w-auto" variant="outline-secondary" onClick={onPrint}><Lucide icon="Printer" className="w-4 h-4 mr-2" />Print</Button>
					<Menu className="w-1/2 sm:w-auto">
						<Menu.Button as={Button} variant="outline-secondary" className="w-full sm:w-auto">
							<Lucide icon="FileText" className="w-4 h-4 mr-2" /> Export
							<Lucide icon="ChevronDown" className="w-4 h-4 ml-auto sm:ml-2" />
						</Menu.Button>
						<Menu.Items className="w-40">
							<Menu.Item onClick={onExportPdf}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export PDF</Menu.Item>
							<Menu.Item onClick={onExportCsv}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export CSV</Menu.Item>
							<Menu.Item onClick={onExportJson}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export JSON</Menu.Item>
							<Menu.Item onClick={onExportXlsx}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export XLSX</Menu.Item>
							<Menu.Item onClick={onExportHtml}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export HTML</Menu.Item>
						</Menu.Items>
					</Menu>
				</div>
				<Button className="mr-5" onClick={() => {

					if (tabulator.current) {
						tabulator.current.setData()
						setTo('')
						setFrom('')
						setAgentId('')
						setAreaId('')
					}

				}}><Lucide icon="RefreshCcw" className="w-4 h-4 mr-3" /></Button>
				<pre>
				<p>Total Loan Recovered   : {paid}</p>
				<p>Total Pending Recovery : {unpaid}</p></pre>
			</div>
			<div className="overflow-x-auto scrollbar-hidden">
				<div id="tabulator" ref={tableRef} className="mt-5"></div>
			</div>
			<div className='flex w-full'>
				<span className='ml-auto mr-10'><b>Total Pages: </b>{totPages}</span>
			</div>
		</div>

	</>
}