/* eslint-disable react-hooks/exhaustive-deps */
import { Transition } from "react-transition-group";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import { selectSideMenu, setFinanceMenu, setMenu } from "../../stores/sideMenuSlice";
import { useAppDispatch, useAppSelector } from "../../stores/hooks";
import { FormattedMenu, linkTo, enter, leave, nestedMenu } from "./side-menu";
import Lucide from "../../base-components/Lucide";
import logoUrl from "../../../src/assets/images/uks_logo-transformed.png";
import clsx from "clsx";
import TopBar from "../../components/TopBar";
import MobileMenu from "../../components/MobileMenu";
import SideMenuTooltip from "../../components/SideMenuTooltip";
import { useLoader } from "../../base-components/Loader";

function Main() {
	const [formattedMenu, setFormattedMenu] = useState<Array<FormattedMenu | "divider">>([]);

	const { showLoader, hideLoader } = useLoader();
	//const [sideMenuStore, setSideMenuStore] = useState();
	//const location = useLocation();
	const location = useLocation();
	const sideMenuStore = useAppSelector(selectSideMenu);
	const sideMenu = () => nestedMenu(sideMenuStore, location);
	useEffect(() => { setFormattedMenu(sideMenu()); }, [sideMenuStore, location.pathname]);

	const dispatch = useAppDispatch();
	const loadMenu = async () => {
		try {
			showLoader()
			const f = await fetch('/api/nav/Finance')
			if (f.ok) {
				const modifiedMenu = await f.json()
				//const modifiedMenu = x.map(i => {
				//	if (i !== 'divider' && i.subMenu) {i.subMenu = i.subMenu.map(s => ({ ...s, pathname: '/admin' + (s.pathname == '' ? s.pathname : '/' + s.pathname) }));}
				//	return i;
				//});
				//console.log("next step",[ modifiedMenu ])
				dispatch(setFinanceMenu(modifiedMenu))
				//dispatch(setMenu(modifiedMenu))
			} else console.error(f.status, f.statusText)
		} catch (e) {
			//ignore
		} finally {
			hideLoader()
			//ignore
		}
	}
	useEffect(() => {
		//console.log('loadMenu()')
		loadMenu();
	}, []);

	return (
		<div className="py-2">
			<MobileMenu />
			<div className="flex mt-[4.7rem] md:mt-0">
				<nav className="pr-5 pb-16 overflow-x-hidden hidden md:block w-[85px] xl:w-[230px]">
					<Link to="/side-menu/dashboard-overview-1" className="flex items-center pt-4 pl-5 intro-x" >
						<img alt="Catering Management Application" className="w-20" src={logoUrl} />
						<span className="hidden ml-3 text-lg text-white xl:block">Microfinance</span>
					</Link>
					<Divider type="div" className="my-6" />
					<ul>
						{formattedMenu.map((menu, menuKey) =>
							menu == "divider" ? (<Divider type="li" className={clsx(["my-6", `opacity-0 animate-[0.4s_ease-in-out_0.1s_intro-divider] animate-fill-mode-forwards animate-delay-${(menuKey + 1) * 10}`,])} key={menuKey} />)
								: (
									<li key={menuKey}>
										<Menu className={clsx({ [`opacity-0 translate-x-[50px] animate-[0.4s_ease-in-out_0.1s_intro-menu] animate-fill-mode-forwards animate-delay-${(menuKey + 1) * 10}`]: !menu.active, })}
											formattedMenuState={[formattedMenu, setFormattedMenu]} menu={menu} level="first" />
										{menu.subMenu && (
											<Transition in={menu.activeDropdown} onEnter={enter} onExit={leave} timeout={300}>
												<ul className={clsx(["bg-black/10 rounded-lg dark:bg-darkmode-900/30",
													{ block: menu.activeDropdown }, { hidden: !menu.activeDropdown },
												])}>
													{menu.subMenu.map((subMenu, subMenuKey) => (
														<li key={subMenuKey}>
															<Menu
																className={clsx({ [`opacity-0 translate-x-[50px] animate-[0.4s_ease-in-out_0.1s_intro-menu] animate-fill-mode-forwards animate-delay-${(subMenuKey + 1) * 10}`]: !subMenu.active, })}
																formattedMenuState={[formattedMenu, setFormattedMenu,]}
																menu={subMenu} level="second" />
															{subMenu.subMenu && (
																<Transition in={subMenu.activeDropdown} onEnter={enter} onExit={leave} timeout={300}>
																	<ul
																		className={clsx(["bg-black/10 rounded-lg dark:bg-darkmode-900/30",
																			{ block: subMenu.activeDropdown, },
																			{ hidden: !subMenu.activeDropdown },
																		])}>
																		{subMenu.subMenu.map(
																			(lastSubMenu, lastSubMenuKey) => (
																				<li key={lastSubMenuKey}>
																					<Menu className={clsx({ [`opacity-0 translate-x-[50px] animate-[0.4s_ease-in-out_0.1s_intro-menu] animate-fill-mode-forwards animate-delay-${(lastSubMenuKey + 1) * 10}`]: !lastSubMenu.active, })}
																						formattedMenuState={[formattedMenu, setFormattedMenu,]} menu={lastSubMenu} level="third" />
																				</li>
																			)
																		)}
																	</ul>
																</Transition>
															)}
														</li>
													))}
												</ul>
											</Transition>
										)}
									</li>
								)
						)}
					</ul>
				</nav>
				<div className="rounded-[30px] min-w-0 min-h-screen flex-1 pb-10 bg-slate-100 dark:bg-darkmode-700 px-4 md:px-[22px] max-w-full md:max-w-auto before:content-[''] before:w-full before:h-px before:block">
					<TopBar />
					<Outlet />
				</div>
			</div>
		</div>
	);
}

function Menu(props: {
	className?: string;
	menu: FormattedMenu;
	formattedMenuState: [(FormattedMenu | "divider")[], Dispatch<SetStateAction<(FormattedMenu | "divider")[]>>];
	level: "first" | "second" | "third";
}) {
	const navigate = useNavigate();
	const [formattedMenu, setFormattedMenu] = props.formattedMenuState;
	//console.log(">>>>>props", props)
	return (
		<SideMenuTooltip as="a" content={props.menu.title}
			href={props?.menu?.subMenu ? "#" : props.menu.pathname}
			className={clsx(["h-[50px] flex items-center pl-5 text-white mb-1 relative rounded-full",
				{ "hidden": props.menu.hidden },
				{
					"dark:text-slate-300": props.menu.active && props.level != "first",
					"text-white/70 dark:text-slate-400": !props.menu.active && props.level != "first",
					"z-10 bg-slate-100 dark:bg-darkmode-700": props.menu.active && props.level == "first",
					"before:content-[''] before:w-[30px] before:h-[30px] before:-mt-[30px] before:rotate-90 before:scale-[1.04] before:bg-[length:100%] before:bg-menu-corner before:absolute before:top-0 before:right-0 before:-mr-5 dark:before:bg-menu-corner-dark": props.menu.active && props.level == "first",
					"after:content-[''] after:w-[30px] after:h-[30px] after:mt-[50px] after:scale-[1.04] after:bg-[length:100%] after:bg-menu-corner after:absolute after:top-0 after:right-0 after:-mr-5 dark:after:bg-menu-corner-dark": props.menu.active && props.level == "first",
					"[&>div:nth-child(1)]:hover:before:bg-white/5 [&>div:nth-child(1)]:hover:before:dark:bg-darkmode-500/70": !props.menu.active && !props.menu.activeDropdown && props.level == "first",
				},
				props.className,
			])}
			onClick={(event: React.MouseEvent) => {
				event.preventDefault();
				linkTo(props.menu, navigate);
				setFormattedMenu([...formattedMenu]);
			}}>
			<div
				className={clsx({
					"text-primary dark:text-slate-300": props.menu.active && props.level == "first",
					"dark:text-slate-400": !props.menu.active && props.level == "first",
					"before:content-[''] before:z-[-1] before:absolute before:top-0 before:right-0 before:-mr-5 before:w-12 before:h-full before:bg-slate-100 before:dark:bg-darkmode-700": props.menu.active && props.level == "first",
					"before:content-[''] before:z-[-1] before:w-[230px] before:absolute before:top-0 before:left-0 before:h-full before:rounded-l-full before:transition before:ease-in before:duration-100": !props.menu.activeDropdown && !props.menu.active && props.level == "first",
				})}>
				<Lucide icon={props.menu.icon} />
			</div>
			<div
				className={clsx(["hidden xl:flex items-center w-full ml-3",
					{ "font-medium": props.menu.active && props.level != "first" },
					{ "text-slate-800 font-medium dark:text-slate-300": props.menu.active && props.level == "first", },
					{ "dark:text-slate-400": !props.menu.active && props.level == "first", },
				])}>{props.menu.title}
				{props.menu.subMenu && (
					<div className={clsx(["transition ease-in duration-100 ml-auto mr-5 hidden xl:block", { "transform rotate-180": props.menu.activeDropdown },])}>
						<Lucide className="w-4 h-4" icon="ChevronDown" />
					</div>
				)}
			</div>
		</SideMenuTooltip>
	);
}

function Divider<C extends React.ElementType>(props: { as?: C } & React.ComponentPropsWithoutRef<C>) {
	const { className, ...computedProps } = props;
	const Component = props.as || "div";

	return (<Component {...computedProps} className={clsx([props.className, "w-full h-px bg-white/[0.08] z-10 relative dark:bg-white/[0.07]",])} />);
}

export default Main;
