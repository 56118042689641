import { NavLink } from "react-router-dom";
import img from './bg-img2.jpg'
import Dialog from "../../base-components/Headless/Dialog";
import { useState } from "react";
import Button from "../../base-components/Button";
import FormLabel from "../../base-components/Form/FormLabel";
import FormInput from "../../base-components/Form/FormInput";
import clsx from "clsx";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import './client.css'
import { useLoader } from "../../base-components/Loader";
import { useNavigate, useParams } from "react-router-dom";
function Main() {
    const navigate = useNavigate()
    const { showLoader, hideLoader } = useLoader();
    const [basicModalPreview, setBasicModalPreview] = useState(false);
    const initClient = { name: '', mobile: '',address:'',email:'' }
    const [expenses, setExpenses] = useState(initClient)
    const schema = yup.object().shape({
        mobile: yup.string().required(),
        name: yup.string().trim().required().min(2),
        address: yup.string().required().min(4),
        email: yup.string().required('number is required'),

    }).required();

    const { register, trigger, formState: { errors }, } = useForm({//defaultValues: client,
        criteriaMode: 'all', mode: "onSubmit", shouldFocusError: true,
        values: expenses, reValidateMode: 'onChange',
        resolver: yupResolver(schema),
    })
    function getFormValuesAsJson(form: HTMLFormElement): any {
        const formData = new FormData(form);
        const values: any = {};

        for (const entry of formData.entries()) {
            const [name, value] = entry;
            if (name === '') continue;
            if (Object.prototype.hasOwnProperty.call(values, name)) {
                if (typeof values[name] === 'string') values[name] = [values[name], value];
                else values[name].push(value);
            } else values[name] = value;
        }
        return values;
    }
    const onSubmit2 = async (event: React.ChangeEvent<HTMLFormElement>) => {
        
        event.preventDefault()
        try {
            const result = await trigger()
            if (result) {
                showLoader()
                const updatedExpenses = getFormValuesAsJson(event.target);
                setExpenses(updatedExpenses);
                const cl = { name: expenses.name, email: expenses.email, mobile: expenses.mobile, address: expenses.address }
                //console.log(cl)
                const d = JSON.stringify(cl)
                const f = await fetch('/api/demo-enquery', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: d, })
                if (f.ok) {
                    const data = await f.json();
                    /*console.log("success", data);*/
                    //setInvalidAlert(true);
                    navigate("/auth");
                } else console.error(f.status, f.statusText)
            } else console.log('validation error')
        } catch (error) { console.error(error) } finally { hideLoader() }
    }

    const handleChange = (e) => {
        const name = e.target.value
        setExpenses((recentData) => ({ ...recentData, [e.target.name]: name }))

    }

	return (
		<>
            <div className="bg-white text-gray-700">
                {/* Header */}
                <header className="flex justify-between items-center py-4 px-8 bg-blue-800 text-white">
                    <div className="text-2xl font-bold">MICROFINANCE</div>
                    <nav>
                        <ul className="flex space-x-6">

                            <Button className="bg-yellow-400 text-black px-4 py-2 rounded mr-4" variant="primary" onClick={() => setBasicModalPreview(true)}>Login</Button>
                           {/* <NavLink to='/auth' className='text-white bg-yellow-400 text-black px-4 py-2 rounded mr-4'>Login</NavLink>*/}
                            
                        </ul>
                    </nav>
                </header>

                {/* Hero Section */}
                <section
                    className="h-screen w-full relative flex items-center justify-center bg-cover bg-center"
                    style={{ backgroundImage: `url(${img})` }}
                >
                    <div className="bg-opacity-50 bg-black p-8 text-white text-center">
                        <h1 className="text-4xl font-bold">Empowering Dreams with Financial Freedom</h1>
                        <div className="mt-6">
                            <Button className="bg-yellow-400 text-black px-4 py-2 rounded mr-4" variant="primary" onClick={() => setBasicModalPreview(true)}>Login</Button>
                            
                        </div>
                    </div>
                </section>
                <footer className="bg-blue-800 text-white py-8">
                    <div className="max-w-6xl mx-auto flex justify-between">
                        <div>
                            <h4 className="font-bold">MICROFINANCE</h4>
                            <p>Empowering financial independence since 2020</p>
                            <p>+91 9511726780</p>
                        </div>
                        <ul className="flex space-x-6">
                            <li><a href="https://rasankarcs.in/">Website</a></li>
                            <li><a href="#">LinkedIn</a></li>
                            <li><a href="#">Twitter</a></li>
                        </ul>
                    </div>
                </footer>
               
            </div>
			<Dialog open={basicModalPreview} onClose={() => { setBasicModalPreview(false); }}>
				<Dialog.Panel className="p-10 text-center">
                    <div className="grid grid-cols-12 gap-6 mt-5">
                        <div className="col-span-12 intro-y lg:col-span-12">
                            <div className="p-5 intro-y box">
                                <form className="validate-form" onSubmit={onSubmit2}>
                                    <div className="input-form mb-5">
                                        <FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row"> Name
                                            {/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">Required, at least 2 characters</span>*/}
                                        </FormLabel>
                                        <FormInput id='fiName' {...register("name")} type="text" name="name" value={expenses.name} placeholder="Enter Name"
                                            onChange={handleChange} className={clsx({ "border-danger": errors.name })} autoComplete='off' />
                                        {errors.name && (<div className="mt-2 text-danger">{typeof errors.name.message === "string" && errors.name.message}</div>)}
                                    </div>
                                <div className="input-form mb-5">
                                        <FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row"> Address
                                            {/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">Required, at least 2 characters</span>*/}
                                        </FormLabel>
                                        <FormInput id='fiName' {...register("address")} type="text" name="address" value={expenses.address} placeholder="Enter address"
                                            onChange={handleChange} className={clsx({ "border-danger": errors.address })} autoComplete='off' />
                                        {errors.address && (<div className="mt-2 text-danger">{typeof errors.address.message === "string" && errors.address.message}</div>)}
                                    </div>
                                <div className="input-form mb-5">
                                        <FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row"> Mobile No
                                            {/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">Required, at least 2 characters</span>*/}
                                        </FormLabel>
                                        <FormInput id='fiName' {...register("mobile")} type="text" name="mobile" value={expenses.mobile} placeholder="Enter Mobie No"
                                            onChange={handleChange} className={clsx({ "border-danger": errors.mobile })} autoComplete='off' />
                                        {errors.mobile && (<div className="mt-2 text-danger">{typeof errors.mobile.message === "string" && errors.mobile.message}</div>)}
                                    </div>
                               <div className="input-form mb-5">
                                        <FormLabel htmlFor="email" className="flex flex-col w-full sm:flex-row">Email
                                            {/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">Required, at least 2 characters</span>*/}
                                        </FormLabel>
                                        <FormInput id='email' {...register("email")} type="text" name="email" value={expenses.email} placeholder="Enter Email"
                                            onChange={handleChange} className={clsx({ "border-danger": errors.email })} autoComplete='off' />
                                        {errors.email && (<div className="mt-2 text-danger">{typeof errors.email.message === "string" && errors.email.message}</div>)}
                                    </div>
                                    <Button variant="primary" type="submit" className="m-2">Go To Demo</Button>
                                </form>
                            </div>
                        </div>
                    </div>

				</Dialog.Panel>
			</Dialog>

		</>
	);
}

export default Main;
