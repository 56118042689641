import { createIcons, icons } from "lucide";
import { createRef, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import '../../../assets/css/tabulator-edit.css';
import { useLoader } from "../../../base-components/Loader";
import jsPDF from "jspdf";
import * as xlsx from "xlsx";
import Button from "../../../base-components/Button";
import Menu from "../../../base-components/Headless/Menu";
import Dialog from "../../../base-components/Headless/Dialog";
import Lucide from "../../../base-components/Lucide";
import FormInput from "../../../base-components/Form/FormInput";
import { FormTextarea } from "../../../base-components/Form";

export default function Main() {
    const navigate = useNavigate()
    const { showLoader, hideLoader } = useLoader();
    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
    const tableRef = createRef<HTMLDivElement>();
    const tabulator = useRef<Tabulator>();
    const [id, setId] = useState(0);
    const sendButtonRef = useRef();
    const deleteButtonRef = useRef(null);
    const [headerFooterModalPreview, setHeaderFooterModalPreview] = useState(false);
    const [successModalPreview, setSuccessModalPreview] = useState(false);

    const [modal, setModal] = useState({ msg: '', title: '', type: '' });

    const [totPages, setTotPages] = useState(0)
    const [category, setCategory] = useState('')
    const [sendWAModalOpen, setSendWAModalOpen] = useState(false)
    const [msg, setMsg] = useState('')
    var deleteIcon = function () { return '<i data-lucide="trash2" class="w-8 h-8 mr-1 text-danger"></i>'; };

    useEffect(() => {//setSuccessModalPreview(true)
        initTabulator();
        reInitOnResizeWindow();
    }, []);
    useEffect(() => {setMsg('')}, [sendWAModalOpen]);

    const initTabulator = () => {
        if (tableRef.current) {
            tabulator.current = new Tabulator(tableRef.current, {//groupBy: "type",//data: dataList,
                ajaxFiltering: true,
                ajaxURL: "/api/agent-list", paginationMode: "remote", filterMode: "remote", sortMode: "remote",
                movableColumns: true, ajaxResponse: (_url, p, res) => {
                    setTotPages(res.last_page)
                    const page = p.page, size = p.size
                    const processedData = res.data.map((row, index) => ({ ...row, srno: ((page - 1) * size) + index + 1, }));
                    res.data = processedData
                    return res
                }, //placeholderHeaderFilter:"ijn",
                pagination: true, paginationSize: 10, paginationSizeSelector: [10, 20, 30, 40], paginationButtonCount: 10,
                reactiveData: true, height: 'auto', layout: "fitDataStretch", placeholder: "No matching records found",
                columns: [
                    { title: "Sr.", field: "srno", minWidth: 30, print: true, hozAlign: "center", headerHozAlign: 'center', headerSort: false },
                    { title: "Name", field: "name", minWidth: 150, print: true, hozAlign: "center", headerHozAlign: 'center', validator: "required", headerSort: false },
                    { title: "Mobile", field: "mobile", minWidth: 150, print: true, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "input", validator: "required", headerSort: false },
                    { title: "Password", field: "password", minWidth: 150, print: true, hozAlign: "center", headerHozAlign: 'center', },
                    { title: "Email", field: "email", minWidth: 150, print: true, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "input", validator: "required", headerSort: false },
                    { title: "Address", field: "address", minWidth: 150, print: true, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "input", validator: "required", headerSort: false },
                    { title: "Date", field: "date", minWidth: 150, print: true, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "input", validator: "required", headerSort: false },
                    //{
                    //    title: "Actions", print: false, formatter: deleteIcon, headerSort: false, headerHozAlign: "center", width: 100, hozAlign: "center",
                    //    //cellClick: function (_e, row) {const id = (row.getData() as any).id}
                    //}
                    {
                        title: 'Edit', minWidth: 100, headerSort: false,
                        formatter: () => '<i data-lucide="edit" class="w-8 h-8 mr-1 text-success"></i>',
                        cellClick: (e, cell) => {
                            const planData = cell.getRow().getData();

                            navigate(`/finance/agent-creation/${planData.id}`);
                        },
                    },
                    {
                        title: 'Delete', minWidth: 80, headerSort: false,
                        formatter: () => '<i data-lucide="trash2" class="w-8 h-8 mr-1 text-danger"></i>',
                        cellClick: (e, cell) => {
                            const planData = cell.getRow().getData();
                            setId(planData.id);
                            setDeleteConfirmationModal(true);
                        },
                    },
                ],
            });
        }

        tabulator.current?.on("renderComplete", () => { createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", }); });
    };
    async function deleteprocess() {
        try {
            showLoader()//body: JSON.stringify(process), 
            const f = await fetch(`/api/agent-list/${id}`, { method: 'DELETE', headers: { 'Content-Type': 'application/json', }, })
            if (f.ok) {
                const j = await f.json()
                if (j.e) { }
                else {
                    tabulator.current.setData();
                    setDeleteConfirmationModal(false);
                }
            } else console.error(f.status, f.statusText)
        } catch (e) { console.error(e) } finally { hideLoader() }
    }
    const reInitOnResizeWindow = () => {
        window.addEventListener("resize", () => {
            if (tabulator.current) {
                tabulator.current.redraw();
                createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", });
            }
        });
    };

    const onSubmitMsg = async () => {
        //event.preventDefault()
        try {


            showLoader()
            //const updatedExpenses = getFormValuesAsJson(event.target);
            //setType(updatedExpenses);		
            const cl = { msg: msg }
            const d = JSON.stringify(cl)
            const f = await fetch('/api/agent-msg', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: d, })
            if (f.ok) {
                const data = await f.json();
                
                //console.log("success", data);
            } else console.error(f.status, f.statusText)

        } catch (error) { console.error(error) } finally { hideLoader() }
    }
    
    const LoadMsg = async () => {
        try {
            showLoader()          
            const f = await fetch('/api/agent-msg')
            if (f.ok) {
                const data = await f.json();
                setMsg(data.msg);
                //console.log("success", data);
            } else console.error(f.status, f.statusText)

        } catch (error) { console.error(error) } finally { hideLoader() }
    }

    const onPrint = () => { if (tabulator.current) tabulator.current.print(); };
    const onExportPdf = () => {
        if (tabulator.current) {
            const doc = new jsPDF({ orientation: 'portrait', unit: 'px', format: 'a4' })
            doc.autoTable({
                head: [['Sr.', 'Name', 'Mobile','Password', 'Email','Address','Date', ]],
                body: tabulator.current.getData().map(row => [row.srno, row.name, row.mobile,row.email,row.address,row.password, row.date,]),
            });

            doc.save('Expenses.pdf')
        }
        // tabulator.current.download("pdf", "data.pdf",{ jsPDF,orientation: 'portrait', title: '', autoTable: { styles: { fillColor: [255, 0, 0] } } });
    };
    const onExportCsv = () => { if (tabulator.current) tabulator.current.download("csv", "data.csv"); };
    const onExportJson = () => { if (tabulator.current) tabulator.current.download("json", "data.json"); };
    const onExportHtml = () => { if (tabulator.current) tabulator.current.download("html", "data.html", { style: true, }); };

    const onExportXlsx = () => {
        if (tabulator.current) {
            (window as any).XLSX = xlsx;
            tabulator.current.download("xlsx", "data.xlsx", { sheetName: "Products", });
        }
    };
	return <>
        <div className="flex flex-col items-center mt-8 intro-y sm:flex-row"><h2 className="mr-auto text-lg font-medium">Agent List</h2>
            <div className="flex w-full mt-4 sm:w-auto sm:mt-0">
                <Button variant="primary" className="mr-2 shadow-md" onClick={() => { setSendWAModalOpen(true), LoadMsg() }} >Send Massage</Button>
                <Button variant="primary" className="mr-2 shadow-md" onClick={() => navigate('/finance/agent-creation')} >Add Agent</Button>
            </div>
        </div>
        <div className="p-5 mt-5 intro-y box">
            <div className="flex flex-col sm:flex-row sm:items-end xl:items-start">
                <div className="xl:flex sm:mr-auto">
                    <Button id="tabulator-print" className="w-1/2 mr-2 sm:w-auto" variant="outline-secondary" onClick={onPrint}><Lucide icon="Printer" className="w-4 h-4 mr-2" />Print</Button>
                    <Menu className="w-1/2 sm:w-auto">
                        <Menu.Button as={Button} variant="outline-secondary" className="w-full sm:w-auto">
                            <Lucide icon="FileText" className="w-4 h-4 mr-2" /> Export
                            <Lucide icon="ChevronDown" className="w-4 h-4 ml-auto sm:ml-2" />
                        </Menu.Button>
                        <Menu.Items className="w-40">
                            <Menu.Item onClick={onExportPdf}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export PDF</Menu.Item>
                            <Menu.Item onClick={onExportCsv}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export CSV</Menu.Item>
                            <Menu.Item onClick={onExportJson}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export JSON</Menu.Item>
                            <Menu.Item onClick={onExportXlsx}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export XLSX</Menu.Item>
                            <Menu.Item onClick={onExportHtml}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export HTML</Menu.Item>
                        </Menu.Items>
                    </Menu>
                </div>

            </div>
            <div className="overflow-x-auto scrollbar-hidden">
                <div id="tabulator" ref={tableRef} className="mt-5"></div>
            </div>
            <div className='flex w-full'>
                <span className='ml-auto mr-10'><b>Total Pages: </b>{totPages}</span>
            </div>
        </div>
        <Dialog open={deleteConfirmationModal} initialFocus={deleteButtonRef} onClose={() => { setDeleteConfirmationModal(false); }}>
            <Dialog.Panel>
                <div className="p-5 text-center">
                    <Lucide icon="Trash2" className="w-16 h-16 mx-auto mt-3 text-danger" />
                    <div className="mt-5 text-3xl">Are you sure?</div>
                    <div className="mt-2 text-slate-500">
                        Delete ? <br />
                        This process cannot be undone.
                    </div>
                </div>
                <div className="px-5 pb-8 text-center">
                    <Button variant="outline-secondary" onClick={() => { setDeleteConfirmationModal(false); }} className="w-24 mr-1">Cancel</Button>
                    <Button variant="danger" className="w-24" ref={deleteButtonRef} onClick={deleteprocess} >Delete</Button>
                </div>
            </Dialog.Panel>
        </Dialog>
        <Dialog open={sendWAModalOpen} onClose={() => { setSendWAModalOpen(false) }}>
            <Dialog.Panel>
                <Dialog.Title>
                    <h2 className="mr-auto text-base font-medium">Send Message To All Agent</h2>
                    <Button className="w-24 ml-auto" onClick={() => { setSendWAModalOpen(false) }}><Lucide icon='XCircle' /></Button>
                </Dialog.Title>
                <div className="mx-5 my-3">
                    <label className='font-medium'> Massage</label>
                    <FormTextarea placeholder='Enter Message' className='mb-5' onChange={(e) => setMsg(e.target.value)} value={msg} />
                </div>
                <Dialog.Footer className='mt-1'>
                    <Button type="button" variant="primary" className=" mr-2"
                        onClick={() => {
                            onSubmitMsg();
                            setSendWAModalOpen(false)
                        }}> Send </Button>
                </Dialog.Footer>
            </Dialog.Panel>
        </Dialog>
	</>
}